import { Telegram } from "@material-ui/icons";
import { de } from "date-fns/locale";

export const diccionario_es = {
  hola: "Hola",
  Leida: "Leída",
  Archivada: "Archivada",
  empleados: "Empleados",
  BloquearReserva: "Bloquear Reserva ",
  empleado: "Empleado",
  empezar: "empezar",
  empezada: "empezada",
  organizacion: "Organización",
  estructura: "Estructura",
  organizativa: "organizativa",
  crear: "Crear",
  visualizar: "Visualizar",
  modificar: "Modificar",
  listados: "Listados",
  listado: "Listado",
  recursos: "Recursos Humanos",
  resumen: "Resumen",
  evaluados: "Evaluados",
  evaluado: "Evaluado",
  evaluaciones: "Evaluaciones",
  evaluacion: "Evaluación",
  evaluada: "Evaluada",
  pruebas: "Pruebas",
  areas: "Áreas",
  evainiciadas: "Eva. iniciadas",
  abstencion: "Abstención",
  evarealizadas: "Eva. realizadas",
  ranking: "Ranking empleados por evaluación",
  nombre: "Nombre",
  Nombre: "Nombre",
  apellidos: "Apellidos",
  area: "Área",
  estado: "Estado",
  fecha: "Fecha",
  resultado: "Resultado",
  todas: "Todas",
  año: "año",
  competencias: "competencias",
  destacadas: "destacadas",
  buenas: "buenas",
  normales: "normales",
  alejadas: "alejadas",
  submitida: "submitida",
  submitidas: "submitidas",
  submitir: "submitir",
  completas: "completas",
  periodos: "Periodos",
  fomulario: "Formulario",
  actualizar: "Actualizar",
  busqueda: "Campos de búsqueda",
  rellenar: "Rellena un campo con el valor del empleado que deseas recuperar",
  rellenar2: "Rellena un campo con el valor dela estructura que deseas recuperar",
  limpiarcampos: "Limpiar campos",
  seleccionado: "seleccionado",
  hombre: "Hombre",
  mujer: "Mujer",
  otros: "Otros",
  genero: "Género",
  cargo: "Cargo",
  acceso: "Acceso",
  herramienta: "herramienta",
  registro: "registro",
  telefono: "Teléfono",
  inicio: "inicio",
  Bueno: "Bueno",
  Destacado: "Destacato",
  Alejado: "Alejado",
  Normal: "Normal",
  sin: "Sin",
  finalizar: "finalizar",
  aviso0: "Debes al menos responder alguna pregunta del cuestionario para empezar la evaluación",
  aviso1: "Para poder finalizar la evaluación debes de responder todas las cuestiones",
  aviso2: "Para submitir la evaluación debes activar el botón 'Submitir'",
  aviso3: "EVALUACÍON SUBMITIDA, cualquier modificación ponte en contacto con el administrador",
  gerente: "Gerente",
  observaciones: "Observaciones",
  aviso4: "¿Quieres reabrir la evaluación?",
  aviso5: "¿Quieres submitir la evaluación?, Una vez submitida ya no la podrás modificar",
  deLaOrganizacion: "DE LA ORGANIZACIÓN (CORE)",
  PERSONALES: "PERSONALES",
  delPuesto: "DEL PUESTO",
  funcionales: "(FUNCIONALES)",
  INTERPERSONALES: "INTERPERSONALES",
  delPuestoFuncionales: "DEL PUESTO (FUNCIONALES)",
  Email: "Email",
  Fecha_Creacion: "F. alta",
  Id: "Identificador",
  SOSBloqueo: "SOS",
  Codigo_seguridad: "Código de seguridad (max 5 dígitos)",
  Codigo_vecino: "Código de registro de la comunidad",
  Cp: "C.P.",
  Poblacion: "Población",
  Seguro_nombre: "Compañía de Seguros",
  tooltip: "Compañia de seguros asociada a la Comunidad",
  Poliza: "Póliza",
  IBAN: "Cuenta IBAN",
  Vecinos: "Nº Vecinos",
  Pago_App: "Pago Mensual APP",
  Cif: "CIF",
  tip_comunidad: "Compañia de seguros asociada a la Comunidad",
  listaComunidades: "Lista de comunidades",
  Direccion: "Dirección",
  Provincia: "Provincia",
  estadoComunidad: "Estado",
  Altac365: "Alta c365",
  Fecha_Baja: "F. Baja",
  Bloqueada: "Bloqueada",
  tip_Bloqueada: "Pulsa para bloquear/desbloquear el acceso de los usuarios a la app",
  tip_Alta: "Pulsa para contratar Comunidad 365",
  tip_Baja: "Pulsa para actualizar contrato o darlo de baja",
  tituloBloquear: "Bloquear comunidad",
  tituloDesBloquear: "Desbloquear comunidad",
  cuerpoBloquear: "Pulse aceptar para confirmar el bloqueo de la comunidad",
  cuerpoDesBloquear: "Pulse aceptar para confirmar el desbloqueo de la comunidad",
  tip_EnvioComunidades: "Envía avisos a las comunidades previamente seleccionadas",
  ENVIAR_AVISO: "Enviar aviso",
  Buscar: "Buscar",
  Buscador: "Buscador",
  Inicio: "Inicio",
  Comunidades: "Comunidades",
  Incidencias: "Incidencias",
  Gremios: "Proveedores",
  Roles: "Roles",
  Notificaciones: "Notificaciones",
  Despacho: "Despacho",
  Documentos: "Documentos",
  Usuarios: "Usuarios",
  DescripcionMenuInicial: "Descripción Menú Inicial",
  CIF: "CIF",
  Administracion: "Administración",
  AvisoLegal: "Aviso Legal",
  PoliticaPrivacidad: "Política de Privacidad",
  PoliticaCookies: "Política de Cookies",
  DescComunidades: "Gestión de comunidades",
  DescIncidencias: "Gestión de incidencias",
  DescGremios: "Gestión de proveedores",
  DescRoles: "Gestión de roles",
  DescUsuarios: "Gestión de usuarios",
  DescNotificaciones: "Gestión de Notificaciones",
  DescAdministracion: "Gestión de despacho",
  Juntas: "Juntas",
  Votaciones: "Votaciones",
  VotacionesOrdenDia: "Orden del día/Votaciones",
  ZonasComunes: "Zonas Comunes",
  Documentacion: "Documentación",
  Actividades: "Actividades",
  Actividad: "Actividad",
  DescJuntas: "Gestión de Juntas",
  DescVotaciones: "Gestión de Votaciones",
  DescZonasComunes: "Gestión de Zonas Comunes",
  DescDocumentacion: "Gestión de Documentacion",
  DescActividades: "Gestión de Actividades",
  Detalle: "Detalle",
  Mostrando: "Mostrando",
  a: "a",
  de: "de",
  resultados: "resultados",
  HasSeleccionado: "Has seleccionado",
  comunidades: "comunidades",
  paraAviso: "para el envío de AVISO",
  comunidad: "comunidad",
  Comunidad: "Comunidad",
  exportarPdf: "Exportar a PDF",
  exportarCsv: "Exportar a CSV",
  filasPagina: "Filas por página",
  comunidadesSeleccionadas: "comunidad/es seleccionada/s para aviso.",
  CERRAR: "CERRAR",
  Restaurar: "Restaurar",
  tituloFormAvisos: "Envío avisos",
  asunto: "Asunto",
  textoAviso: "Texto del aviso",
  URLopcional: "URL opcional",
  diagoloTitulo: "Baja de la comunidad",
  diagoloBody: "¿Está seguro de que desea dar de baja la comunidad?",
  cerrar: "Cerrar",
  guardarCambios: "Guardar cambios",
  diagoloBodyAviso: "¿Está seguro de que desea enviar el aviso?",
  diagoloTituloAviso: "Envío de aviso",
  Guardar: "Guardar",
  CNAE: "CNAE",
  TlfnPrincipal: "Teléfono (Si hay más de uno deben ir separados por comas)",
  TlfnSecundario: "Teléfono secundario",
  Email1: "Email (Si hay más de uno deben ir separados por comas)",
  Email2: "Email 2",
  idProveedor: "Id Proveedor",
  idComunidad: "Id Comunidad",
  fechaInicioContrato: "Fecha inicio contrato",
  fechaFinContrato: "Fecha fin contrato",
  observaciones: "Observaciones",
  sinresultados: "No se encontraron resultados.",
  cancelar: "Cancelar",
  aceptar: "Aceptar",
  Grupos: "Grupos",
  AccesoATodo: "Acceso a todo",
  Documentacion: "Documentacion",
  ZonasReserva: "Zonas de reserva",
  JuntasYVotaciones: "Juntas y votaciones",
  Observaciones: "Observaciones",
  Opciones: "Opciones",
  Acciones: "Acciones",
  tip_BorrarRol: "Pulsa para borrar un rol",
  confirmarBorradoRol: "Has marcado un rol para darlo de baja, ¿deseas continuar?",
  tituloConfirmarBorradoRol: "Confirmar borrado",
  Anadir: "Añadir",
  Proveedores: "Proveedores",
  SoloPara: "Sólo para:",
  Roles: "Roles",
  Numero: "Número",
  Portal: "Portal",
  Escalera: "Escalera",
  Piso: "Piso",
  Letra: "Letra",
  Inquilino: "Inquilino",
  Multipropiedad: "Multipropiedad",
  Bloqueado: "Bloqueado",
  Usuario: "Usuario",
  AdministradorFincaColegiado: "Administrador de Fincas Colegiado",
  EmpleadoOficina: "Empleado de Oficina",
  RegistroHorario: "Fichajes",
  Ano: "Año",
  SeleccionaAno: "Selecciona un año",
  Entrada: "Entrada",
  Salida: "Salida",
  Centro: "Centro",
  Horas: "Horas",
  Semana: "Semana",
  Fecha: "Fecha",
  TotalHoras: "Total Horas",
  Minutos: "Minutos",
  Modificar: "Modificar",
  Eliminar: "Eliminar",
  Filtrar: "Filtrar",
  tituloNuevoRegistroHorario: "Nuevo registro horario",
  confirmarBorradoRegistroHorario: "¿Deseas borrar el registro horario?",
  tituloConfirmarBorradoRistroHorario: "Borrar registro horario",
  EnviarCheckEmail: "Enviar Emails de verificación",
  tituloModificarRegistroHorario: "Modificar registro horario",
  MiCarpeta: "Mi Carpeta",
  ReseteoContrasena: "Reseteo Contraseña",
  TextoReseteoContrasena:
    "Se va resetear la contraseña del usuario, en el momento que se realice la actualización el usuario deberá acceder a la aplicación con la nueva contraseña",
  AnadeContrasena: "Añade la nueva contraseña (Al menos 6 dígitos)",
  Borrar: "Borrar",
  Todo: "Todo",
  Reservas: "Reservas ",
  ReservasDe: "Reservas libres de",
  Despachos: "Despachos",
  hasCambiadoDespacho: "Ahora estás en el despacho",
  enviarAviso: "Enviar Aviso",
  DNI: "DNI",
  descripcion: "Descripción",
  tipo: "Tipo",
  EliminarUsuario: "Eliminar Usuario",
  ConfirmarEliminarUsuario: "¿Deseas eliminar el usuario?",
  DetalleActividad: "Detalle de la actividad",
  AnadirActividad: "Añadir actividad",
  tip_BorrarActividad: "Pulsa para borrar una actividad",
  confirmarBorradoActividad:
    "Has marcado una actividad para darla de baja, ésta desaparecerá de todas tus comunidades, ¿deseas continuar?",
  tituloConfirmarBorradoActividad: "Confirmar borrado",
  AnadirRol: "Añadir Rol",
  confirmarBorradoVoto: "Has marcado un voto para borrarlo, ¿deseas continuar?",
  tituloConfirmarBorradoVoto: "Confirmar borrado",
  Icono: "Icono",
  Si: "Si",
  No: "No",
  cuerpoDesBloquearUsuario: "¿Deseas desbloquear el usuario ",
  tituloDesBloquearUsuario: "Desbloquear usuario ",
  cuerpoBloquearUsuario: "¿Deseas bloquear el usuario ",
  tituloBloquearUsuario: "Bloquear usuario ",
  Porcentaje: "Porcentaje",
  Proveedor: "Proveedor",
  SeleccionaProveedor: "Busca y selecciona un proveedor...",
  UsuariosRegistradores: "Usuarios registradores",
  FalloPago: "El pago ha fallado, inténtalo de nuevo más tarde.",
  Gobierno: "Junta de gobierno",
  Administración: "Administración",
  Mantenimiento: "Mantenimiento",
  tituloEnvioCorrecto: "Resultado del envío",
  mensajeResultados: "Aviso enviado correctamente a ${var1} destinatarios",
  tip_BorrarGremio: "Pulsa para borrar un proveedor",
  confirmarBorradoGremio: "Has marcado un proveedor para darlo de baja, ¿deseas continuar?",
  tituloConfirmarBorradoGremio: "Confirmar borrado",
  preciosSinIva: "Todos los precios son sin IVA.",
  preciosPorVecino:
    "Los precios varían dependiendo del número de vecinos de la comunidad. (Esta comunidad tiene ${var1} vecinos)",
  preciosPorVecinoHistorico:
    "Los precios varían dependiendo del número de vecinos de la comunidad. (Esta comunidad tenía ${var1} vecinos en el momento de la contratación)",
  CreadoPor: "Creado por",
  estadoContrato: "Estado",
  asociarActividad: "La actividad ahora saldrá en el móvil de los usuarios de la comunidad.",
  desasociarActividad: "La actividad ya no saldrá en el móvil de los usuarios de la comunidad.",
  asociarTodasActividades:
    "Ahora saldrán todas las actividades en el móvil de los usuarios de la comunidad",
  desasociarTodasActividades:
    "Ahora no saldrá ninguna actividad en el móvil de los usuarios de la comunidad",
  EnvioAvisoEmail: "Además del aviso, enviar también por email  a los implicados",
  Codigo: "Código",
  Tipo: "Tipo",
  Actividad: "Actividad",
  Registrador: "Registrado por",
  // Registrador: "Registrador",
  tip_BorrarIncidencias: "Pulsa para borrar la incidencia",
  tip_BorrarNotificaciones: "Pulsa para borrar la notificación",
  notificacionBorrada: "Notificación borrada",
  tip_BorrarVotacion: "Pulsa para borrar la votación",
  tip_compartirIncidencia: "Compartir/publicar la incidencia",
  tip_ModificarRegistroHorario: "Pulsa para editar el registro",
  tip_BorrarRegistroHorario: "Pulsa para borrar el registro",
  tip_BorrarReserva: "Pulsa para cancelar la reserva",
  tituloCriticos: "¡Se han cambiado datos criticos!",
  bodyCriticos:
    "Se devolverán los abonos a los usuarios y se borrarán las reservas futuras para poder guardar la configuración",

  Compartir: "Compartir",
  red: "Rojo",
  yellow: "Amarillo",
  green: "Verde",
  incidenciaGreen: "Incidencia cerrada o cancelada",
  incidenciaYellow: "Incidencia en curso",
  incidenciaRed: "Incidencia sin cerrar desde hace 8 días o más",
  incidenciaRed2: "Incidencia abierta",
  ENVIAR_AVISO_EMAIL: "Enviar Email de Registro",
  adminDesk: {},
  SeleccionaComunidad: "Selecciona una comunidad",
  SeleccionaEstado: "Selecciona un estado",
  SeleccionaActividad: "Selecciona una actividad",
  SeleccionaProveedor2: "Selecciona un proveedor",
  Estado: "Estado",
  ABIERTA: "ABIERTA",
  CERRADA: "CERRADA",
  CANCELADA: "CANCELADA",
  ENCURSO: "EN CURSO",
  SeleccionaTipo: "Selecciona tipo",
  INCIDENCIA: "AVERIA",
  SINIESTRO: "SINIESTRO",
  SOLICITUD: "SOLICITUD",
  CONSULTA: "CONSULTA",
  AVISO: "AVISO",
  SOS: "SOS",
  NuevaIncidencia: "Nueva incidencia",
  DetalleIncidencia: "Detalle de la incidencia",
  DetalleVotacion: "Detalle de la votación",
  CodigoDespacho: "Código de despacho",
  CodigoComunidad: "Código de comunidad",
  SeleccionaRegistrador: "Selecciona un registrador",
  DescripcionIncidencia: "Descripción de la incidencia (visible por los usuarios de la app)",
  DatosGuardadosCorrectamente: "Datos guardados correctamente.",
  ErrorGuardarDatos: "Error al guardar los datos.",
  mensajeDespublicarIncidencia:
    "Al desactivar esta opción la incidencia será privada para los vecinos de la comunidad.",
  mensajePublicarIncidencia:
    "Al activar esta opción la incidencia será publica para todos los vecinos de la comunidad. Cualquier modificación y posterior envío de aviso se realizará a todos los propietarios registrados.",
  Fecha_Modificacion: "Fecha de modificación",
  EmailProveedor: "Email proveedor",
  TelefonoProveedor: "Telefono proveedor",
  ActualizaDescripcionInterna:
    "Notas internas de la incidencia (visibles sólo por los administradores y empleados de oficina)",
  HistoricoDescripcionInterna: "Histórico de la incidencia",
  HistoricoDescripcionInternaVota: "Histórico de la votación",
  TituloBorrarIncidencia: "Confirmar borrado de incidencia",
  CuerpoBorrarIncidencia: "Para marcar la incidencia como borrada pulsa aceptar.",
  TituloCompartirIncidencia: "Confirmar compartir incidencia",
  CuerpoCompartirIncidencia: "Para compartir la incidencia pulsa aceptar.",
  IncidenciaBorradaCorrectamente: "Incidencia borrada correctamente",
  errorBorrarIncidencia: "Error al borrar la incidencia",
  errorBorrarNotificacion: "Error al borrar la notificación",
  NotificacionBorradaCorrectamente: "Notificación borrada correctamente",
  IncidenciaCompartidaCorrectamente: "Incidencia compartida correctamente",
  errorCompartirIncidencia: "Error al compartir la incidencia",
  incidenciaBorrada: "Incidencia borrada",
  votacionBorrada: "Votación borrada",
  notifiacionBorrada: "Notificación borrada",
  incidenciaCompartida: "Incidencia compartida",
  datosRefrescados: "Datos refrescados",
  Documentacion: "Documentación",
  DocumentacionAdjunta: "Documentación adjunta",
  DocumentacionAdjuntaDesc: "Documentos asociados a la incidencia",
  DocumentacionAdjuntaDesc2: "Documentos asociados a la notificación",
  arrastraArchivos: "Arrastra y suelta aquí tus archivos para subirlos en la carpeta",
  TextoPropiedadDocumentos:
    "* Todos los documentos subidos a C365 son propiedad de la comunidad, y serán traspasados en caso de cambio de administrador. Si consideras que un documento no debe ser traspasado, por favor, indícalo en la opción “Modificar” de ese documento",
  textoConfirmarBorradoCarpeta: "¿Estás seguro de que quieres borrar la carpeta?",
  textoConfirmarBorradoCarpetaGeneral:
    "¿Estás seguro de que quieres borrar la carpeta? La carpeta se borrará de todas las comunidades",
  tituloConfirmarBorradoCarpeta: "Borrar carpeta:",
  textoConfirmarBorradoFichero: "¿Estás seguro de que quieres borrar el fichero?",
  textoConfirmarBorradoFicheroGeneral:
    "¿Estás seguro de que quieres borrar el fichero? El fichero se borrará de todas las comunidades",
  tituloConfirmarBorradoFichero: "Borrar fichero:",
  ExitoBorradoFichero: "Fichero borrado correctamente",
  FalloBorradoFichero: "Fallo al borrar el fichero",
  Descargar: "Descargar",
  SeleccionaCompaniaSeguros: "Selecciona CIA de Seguros",
  PolizaSeguros: "Póliza de Seguros",
  Direccion_es: "Dirección/es",
  PorcentajeTotalPropiedad: "% Total",
  TotalReservas: "Total Reservas",
  PorcPropiedad: "% Propiedad",
  ViviendaInquilino: "Vivienda con Inquilino",
  ViviendaRepLegal: "Vivienda con Rep.legal",
  propietario: "Propietario",
  inquilino: "Inquilino",
  representantelegal: "Representante Legal",
  empresa: "Empresa",
  ViviendasTipo: "Viviendas / Tipo",
  SinViviendas: "Sin viviendas",
  AltaCorrecta: "Dado de baja correctamente.",
  FalloBajaComunidad: `Fallo al dar de baja la comunidad`,
  ComunidadNoAlta: "La comunidad no está de alta.",
  ErrorGuardadoDatos: "Error al guardar los datos.",
  ErrorDatosExistentes: "La actividad ${var1} ya existe, por favor utiliza otro nombre.",
  DatosActualizadosCorrectamente: "Datos actualizados correctamente",
  ActividadBorradaCorrectamente: "Actividad borrada correctamente",
  ActividadExistente: "La actividad ${var1} ya existe, por favor utiliza otro nombre.",
  FechaAlta: "Fecha de alta",
  CodigoVecino: "Código Vecino",
  CodigoPostal: "Código Postal",
  NumeroVecinos: "Número de Vecinos",
  Siniestros: "Siniestros",
  ModificacionClientes: "Recibir Solicitudes",
  ConsultasAdmin: "Recibir Consultas",
  ConsultasSeguros: "Consultas seguros",
  Categoria: "Categoria",
  Alta: "Alta",
  ConfirmarBajaComunidad:
    "¿Estás seguro de que quieres dar de baja la comunidad con id ${var1} de C365?",
  MaximoArchivos: "Sólo puedes subir hasta un máximo de ${var1} archivos",
  TipoArchivoNoPermitido: "Tipo de archivo no permitido: ${var1}",
  ArchivoTamañoMaximoSuperado: "El archivo ${var1} excede el tamaño máximo permitido de ${var2} MB",
  ArchivosSubidosCorrectamente: "Archivos subidos correctamente.",
  NombreCarpetaVacio: "El nombre de la carpeta no puede estar vacío.",
  MismoNombreArchivoMismoNivel: "No puedes poner el mismo nombre a un documento del mismo nivel",
  CarpetaCreadaCorrectamente: "Carpeta creada correctamente.",
  ErrorCrearCarpeta: "Error al crear la carpeta.",
  CarpetaMovidaCorrectamente: "Fichero/Carpeta movido correctamente.",
  ErrorMoverCarpeta: "Error al mover el fichero/carpeta.",
  ErrorMoverCarpetaNoPermitido:
    "No puedes mover las carpetas protegidas de Juntas de propietarios y Documentación general",
  Carpeta03NoBorrar: "La carpeta Juntas de Propietarios no se puede borrar/modificar",
  GuardarCambiosModificar: "Para guardar los cambios tienes que modificar el archivo",
  ErrorCambiarTransferibilidad: "Error al cambiar la transferibilidad",
  NombreCambiadoCorrectamente: "Nombre cambiado correctamente.",
  ErrorCambiarNombre: "Error al cambiar el nombre.",
  TipoArchivoNoPermitido: "Tipo de archivo no permitido",
  DocumentosComunidad: "Documentos de la comunidad",
  Busqueda: "Busqueda",
  CarpetaActual: "Carpeta actual :",
  NombreCarpeta: "Nombre de la carpeta",
  Descargar: "Descargar",
  Borrar: "Borrar",
  EnviarAviso: "Enviar aviso",
  AvisoDocumentosSubidosC365:
    "Todos los documentos subidos a C365 son propiedad de la comunidad, y serán traspasados en caso de cambio de administrador. Si consideras que un documento no debe ser traspasado, por favor, indícalo en la opción “Modificar” de ese documento",
  SeleccionarCarpetaMostrarDetalle: "Selecciona una carpeta para mostrar su detalle",
  TipoArchivoNoPermitido: "Tipo de archivo no permitido: ${var1}",
  TodoOk: "todo ok",
  GremioCreadoConecta: "El proveedor ha sido creado por Conecta o no tiene asociada actividad",
  ProveedorBaja: "El proveedor ha sido dado de baja",
  CasoNoCompletado: "caso no contemplado",
  FechaAlta: "Fecha Alta",
  FechaBaja: "Fecha Baja",
  GremioBorradoCorrectamente: "proveedor borrado correctamente",
  Comus: "comus",
  CambioEstado: "Cambio de estado",
  NotificacionUsuarioAceptarOCancelar:
    "Puedes enviar notificación al usuario 			registrador, al administrador y un 	e-mail al proveedor sobre la 		actualización de la incidencia (EN 	CURSO) o (ABIERTA) pulsa 			ACEPTAR ó CANCELAR",
  CambioEstadoIncidencia: "Cambio de estado de incidencia",
  CambioEstadoIncidenciaCodigo:
    "Se ha cambiado de estado la incidencia con código ${var1} de ${var2} a ${var3}",
  CambioEstadoIncidenciaCodigoCerrada:
    "Se ha cambiado de estado la incidencia con código ${var1} ha sido cerrada ",
  ArchivoExcedeTamano: "El archivo excede el tamaño máximo permitido de ${var1} MB",
  CambioTipo: "Cambio de tipo",
  NotificacionIncidenciaAceptarOCancelar:
    "Puedes enviar notificación al 			usuario registrador, al administrador y un e-mail al proveedor 		sobre el cambio del tipo de incidencia a  SINIESTRO, pulsa 			“ACEPTAR” ó “CANCELAR”.",
  CambioTipoIncidencia: "Cambio de tipo de incidencia",
  CambiadoTipoIncidenciaCodigo: "Se ha cambiado el tipo de la incidencia con código ",
  CambiadoTipoAveriaCodigo:
    "Puedes enviar notificación al 		usuario registrador, al administrador y un e-mail al proveedor 	sobre el cambio del tipo de incidencia a  AVERÍA, pulsa 		“ACEPTAR” ó “CANCELAR”.",
  FechaModificacion: "Fecha modificación",
  FechaCreacion: "Fecha creación",
  NombreRegistrador: "Nombre Registrador",
  ApellidoRegistrador: "Apellido Registrador",
  RolExiste: "El rol ${var1} ya existe, por favor utiliza otro.",
  DatosRefrescados: "Datos refrescados",
  RolBorradoCorrectamente: "Rol borrado correctamente",
  LimpiarBusqueda: "Limpiar búsqueda",
  LimpiarSeleccionado: "Limpiar seleccionado",
  NoHayRoles: "No hay roles",
  SeleccionarTodos: "Seleccionar todos",
  SeleccionarTodosFiltrado: "Seleccionar todos (filtrado)",
  RolSeleccionado: "Rol selecionado...",
  EmailYaExiste: "El Email ya existe",
  DNIYaExiste: "El DNI ya existe",
  NumeroYaExiste: "Ya existe este número para una junta en esta comunidad",
  TituloYaExiste: "Ya existe este título para una junta en esta comunidad",
  CompletaCamposObligatiorio: "Por favor, completa todos los campos obligatorios.",
  NoValidoIntroduceCorrecto: "El NIF/NIE no es válido, introduce uno correcto",
  NoValidoIntroduceCorrecto2: "El NIF/NIE/CIF no es válido, introduce uno correcto",
  NoValidoIntroduceCorrecto3: "El NIF/CIF no es válido, introduce uno correcto",
  FotoBorradaCorrectamente: "Foto borrada correctamente.",
  FotoGuardadaCorrectamente: "Foto guardada correctamente.",
  AnadirUsuario: "Añadir Usuario",
  FechaIncorrecta: "La fecha es incorrecta.",
  NoFechasVacias: "No se puede dejar una de las fechas vácias",
  HorasMinutos: "Horas minutos",
  RegistroHorarioBorrado: "Registro horario borrado correctamente",
  ErrorBorrandoRegistro: "Error borrando registro",
  HoraSalidaEntradaIncorrecta: "La hora de salida no puede ser menor que la de entrada",
  TodosRolesSeleccionados: "Todos los roles han sido seleccionados.",
  SeleccionarRoles: "Seleccionar roles",
  RegistroInquilinoCambiado: "Registro de inquilino cambiado.",
  ErrorGuardarDatos: "Error al guardar los datos",
  UsuarioEliminadoCorrectamente: "Usuario eliminado correctamente.",
  DNIExisteOtroUsuario: "El DNI ya existe en otro usuario",
  EmailExisteOtroUsuario: "El Email ya existe en otro usuario",
  ContrasenaActualizadaNuevaContrasena: "La contrasena ha sido actualizada. Nueva contrasena: ",
  EmailActualizado: "El nombre de usuario ha sido actualizado. Nuevo usuario: ",
  ErrorCambiarContrsena: "Error al cambiar la contraseña.",
  ErrorCambiarEmail: "Error al cambiar el email/nombre de usuario.",
  ErrorEnviandoEmailConfirmacion: "Error enviando el email de verificación: ",
  EnviadoCorreoConfirmacionA: "Se ha mandado el correo de verificación a ",
  AltaC365: "AltaC365",
  CodigoCompletisimo: "codigoCompletisimo",
  VotoBorradoCorrectamente: "Voto borrado correctamente",
  TipoVotacion: "Tipo de votación",
  Publicar: "Publicar",
  AperturaVotacion: "Apertura votación",
  ResultadoPublicado: "Resultado publicado",
  PublicadoPorPorciento: "Publicado por %",
  AltaComunidadConfigurar:
    "Ya tienes de alta esta comunidad en c365, sigue los siguientes pasos para configurar tu comunidad:",
  BienvenidoC365: "¡Bienvenido a c365!",
  GeneraCodigoVecinos:
    "Haz llegar a tus vecinos el Código de Registro Comunidad o el Código de Registro Vecino para que puedan registrarse en la app.",
  CompletaDatosProveedoresGestionIncidencias:
    "Completa los datos de tus proveedores contratados para gestionar incidencias y siniestros.",
  MarcarActividades:
    "Marca las actividades sobre las que quieras que tus vecinos puedan pasar incidencias.",
  CompletaDocumentos: "Sube la documentación que quieras compartir con los propietarios.",
  IncidenciasYproveedores:
    "Si quieres que las incidencias de una actividad lleguen directamente a un proveedor (además del administrador) da de alta los contratos en proveedores o en actividades.",
  SubeDocumentos: "Sube la documentación que quieras compartir con los propietarios.",
  CreaVotacionesJuntas: "Prepara votaciones y juntas cuando sea necesario.",
  CreaZonasComunes: "Configura las zonas comunes (si tienes contratado el Pack de Zonas Comunes).",
  HabilitaFichajes:
    "Haz llegar a tus vecinos el Código de Registro Comunidad o el Código de Registro Vecino para que puedan registrarse en la app.",
  SubeDoucmentacionComparte: "Sube la documentación que quieras y compártela con los propietarios.",
  PreparaVotacionesJuntas: "Prepara votaciones o juntas cuando sea necesario.",
  ConfiguraZonasComunes:
    "Configura las zonas comunes (si tienes contratado el Pack de Zonas Comunes).",
  HabilitaEmleadosFichar:
    "Habilita a los empleados para que puedan fichar (si tienes contratado el Pack Control Horario).",
  MarcarEnviarAviso: "Marcar para enviar aviso",
  MarcarEnviarAvisoRegistroVecino: "Marcar para enviar aviso o mandar código registro vecino",
  UsuariosActivosComunidades: " Usuarios Activos / Comunidades",
  UsuariosComunidad: "Usuarios Comunidad",
  ComunidadesActivasUsuarios: "Comunidades Activas / Usuarios",
  ComusUsuarios: "Comus Usuarios",
  IncidenciasEstados: "Incidencias/Estados ",
  EstadoNumero: "Estado/Número",
  IncidenciasAverias: "Incidencias Averías/Tipos",
  IncidenciasTodas: "Incidencias todas ",
  CodigoDespacho: "Código de despacho",
  Refrescar: "Refrescar",
  Volver: "Volver",
  AltaDesde: "Alta desde",
  AltaHasta: "Alta hasta",
  NOTIFICACIONES: "NOTIFICACIONES",
  CUADRODEMANDOS: "CUADRO DE MANDOS",
  TODAS: "TODAS",
  ACTIVAS: "ACTIVAS",
  TODOS: "TODOS",
  ACTIVOS: "ACTIVOS",
  ABIERTA: "ABIERTA",
  ENCURSO: "EN CURSO",
  CANCELADA: "CANCELADA",
  CERRADA: "CERRADA",
  Desde: "Desde",
  Hasta: "Hasta",
  hasta: "hasta",
  bloquearReservaTitutlo: "Bloqueo de reserva",
  CerrarSesion: "Cerrar sesión",
  ErrorDemasiadosIntentos:
    "Demasiados intentos, por favor espere un rato antes de repetir la acción.",
  ErrorCamposRequeridos:
    "Los campos resaltados en rojo son obligatorios. Para guardar los cambios deben estar rellenos.",
  ValorAntiguo: "Valor anterior",
  ValorActual: "Valor actual",
  Estado: "Estado",
  Tipo: "Tipo",
  Actividad: "Actividad",
  Proveedor: "Proveedor",
  NombreRegistrador: "Registrador",
  Texto_publicar: "Descripción",
  Email: "Email",
  Email2: "Email 2",
  Telefono1: "Teléfono",
  Telefono2: "Teléfono 2",
  Notas: "Notas internas",
  DerechoDeReservas: "Derecho de Reservas",
  sendRegistrationCodeModaltitle: "Enviar código Registro",
  sendRegistrationCodeModalConfirm:
    "Esta accion enviará por correo las instrucciones y credenciales necesarios para registrarse en la app al usuario: ",
  sendRegistrationCodesModalConfirm:
    "Esta accion enviará por correo las instrucciones y credenciales necesarios que para registrarse en la app a todos los usuarios seleccionados que no estén dados de alta en el sistema.",
  sendRegistrationCodeModalSaveMail: "El Email ha sido modificado, ¿Guardar cambios y continuar?",
  sendRegistrationCodeAllowedTip: "Enviar Código de registro por mail",
  sendRegistrationCodeDeniedTip: "No es posible enviar sin email",
  sendRegistrationCodeSucess: "Correo enviado correctamente.",
  sendRegistrationCodeFailure:
    "Ha ocurrido un problema enviando el correo, inténtalo de nuevo mas tarde.",
  sendRegistrationCodesSucess: "Correos enviado correctamente.",
  sendRegistrationCodesFailure:
    "Ha ocurrido un problema enviando los correos, inténtalo de nuevo mas tarde.",
  sendRegistrationCodesMissingMail:
    " no tiene correo, introducelo en el detalle o deselecciona al usuario antes de continuar.",
  RazonSocial: "Razón Social",
  Correo: "Correo",
  PersonaDeContacto: "Persona de contacto",
  DatosDespacho: "Datos del despacho",
  NombreYapellidos: "Nombre y Apellidos",
  tituloBorradoIncidencia: "Incidencia ${var1} borrada",
  tituloCompartidoIncidencia: "Incidencia ${var1} compartida",
  mensajeBorradoIncidencia:
    "La incidencia con código ${var1}. INCIDENCIA ha sido borrada, pulsa el link para ver el detalle",
  mensajeCompartidoIncidencia:
    "La incidencia con código ${var1}. INCIDENCIA ha sido compartida, pulsa el link para ver el detalle",
  ResultadoPublicadoPorPorciento: "Resultado publicado por %",
  ErrorNoArchivoBorrar: "Error, no ha llegado archivo a borrar",
  PUBLICADA: "PUBLICADA",
  NOPUBLICADA: "NO PUBLICADA",
  SINOABS: "SI/NO/ABS",
  OPCIONES: "OPCIONES",
  NuevaOpcionDeVotacion: "Nueva opcion de votación",
  TituloBorrarVotacion: "Borrar votación",
  CuerpoBorrarVotacion: "¿Estás seguro de que quieres borrar la votación?",
  AnadirVotacion: "Añadir votación",
  ElFicheroExisteNoModificar: "El fichero ${var1} ya existe y no se puede modificar",
  EnviarCambiosRealizados: "Se van a enviar los cambios realizados al usuario/s y adminsitrador",
  CambiarEstadoCerradaNoModificar:
    "¿Quieres cambiar el estado a CERRADA?, si lo cambias ya no podrás modificar la incidencia",
  IncidenciaActualizadaCorrectamente: "La incidencia se ha actualizado correctamente",
  IncidenciaCerradaCambioAdministrador:
    "La incidencia se encuentra cerrada, si deseas hacer algún cambio ponte en contacto con el administrador, gracias",
  IncidenciaCanceladaCambioAdministrador:
    "La incidencia se encuentra cancelada, si deseas hacer algún cambio ponte en contacto con el administrador, gracias",
  IncidenciaNoExiste: "La incidencia no existe.",
  BorrarEstadoAbierta: "Para poder borrar el estado de la incidencia debe estar ABIERTA",
  ArrastraFicheroCargarloVentana:
    "Arrastra el fichero o haz click aquí para cargarlo desde la ventana",
  CargarFicheroAdjuntarFoto: "Haz click aquí para cargar un fichero o adjuntar una foto",
  AnadeComentariosEnvialo: "Añade comentarios y envíalos",
  TextoAnadir: "Texto a añadir",
  NombreZona: "Nombre zona",
  DescripcionIncidencia: "Descripción de la Incidencia",
  UltimaModificacion: "Última Modificación",
  GremioResponsable: "Proveedor Responsable",
  ImagenAsociada: "Imagen asociada: ",
  Gremio: "proveedor: ",
  Incidencia: "Incidencia: ",
  CentrosTrabajo: "Centros de trabajo",
  DocumentacionGeneral: "Documentacion general",
  TodosCorreos: "Todos los correos",
  RecibirIncidencias: "Recibir Averías",
  RecibirSiniestros: "Recibir Siniestros",
  RecibirModificacionClientes: "Recibir Solicitudes",
  RecibirConsultaAdmin: "Recibir Consulta Administrador",
  RecibirConsultaSeguros: "Recibir Consulta Seguros",
  RecibirConsultas: "Recibir Consultas",
  CategoriaUsuarioAdministrador: "Categoría Usuario-Administrador",
  Rol: "Rol",
  Fichar: "Fichar",
  ResetearContrasena: "Resetear Contraseña",
  VerificarEmail: "Verificar correo",
  Eliminar: "Eliminar",
  DetallesUsuario: "Detalles Usuario",
  FechaUltimoAcceso: "Fecha Último Acceso",
  FechaCreacion: "Fecha Creación",
  AnadirRegistro: "Añadir Registro",
  HorasEntrada: "Horas Entrada",
  HorasSalida: "Horas Salida",
  Latitud: "Latitud",
  Longitud: "Longitud",
  Distancia: "Distancia",
  VotacionaBorradaCorrectamente: "Votación borrada correctamente",
  AnadirCentroDeTrabajo: "Añadir Centro de trabajo",
  CodigoAdministrador: "Código de Administrador",
  DatosCentroDeTrabajo: "Datos Centro de trabajo",
  CentrosTrabajoDespacho: "Centros de trabajo del despacho",
  CentrosTrabajoComunidad: "Centros de trabajo comunidad",
  AvisoActividadSinProveedor:
    "Los administradores recibirán siempre las incidencias por actividad, si quieres que las reciba también un proveedor en concreto asócialo desde el detalle del proveedor.",
  AlertaIncidenciaCompartida:
    "La incidencia ya fué compartida. Accede al detalle para descompartir.",
  DocumentoAnadido: "Documento añadido",
  DocumentoEliminado: "Documento eliminado",
  textoEnviarActualizaciones:
    "Enviar emails y notificaciones a los implicados avisando de los cambios en la incidencia",
  tituloEnviarActualizaciones: "Confirmar guardado",
  tip_BorrarZonaComun: "Confirma la operación de borrado",
  confirmarBorradoZonaComun: "¿Estás seguro de que quieres borrar la zona común?",
  ZonaComunBorradaCorrectamente: "Zona común borrada correctamente",
  tituloConfirmarBorrado: "Confirmar borrado",
  UsuariosDespacho: "Usuarios del Despacho",
  zonaPiscina: "Piscina",
  zonaPadel: "Pista Padel",
  zonaTenis: "Pista Tenis",
  zonaSalaReuniones: "Sala Reuniones",
  zonaGimnasio: "Gimnasio",
  zonaOtros: "Otros",
  zonaBarbacoa: "Barbacoa",
  zonaJardinJuegos: "Jardín/Zona juegos",
  zonaFronton: "Frontón",
  zonaSalaInfantil: "Sala infantil",
  zonaSolarium: "Solarium",
  zonaSpa: "Spa",
  zonaPiscina2: "Piscina II",
  zonaBus: "Bus",
  CentroTrabajoCerradoCorrectamente: "Centro de trabajo borrado correctamente",
  tip_BorrarCentroTrabajo: "Pulsa para borrar el centro de trabajo",
  centroDeTrabajoBorrado: "Centro de trabajo borrado",
  CuerpoBorrarCentroTrabajo: "Para borrar el centro de trabajo pulsa aceptar.",
  TituloBorrarCentroTrabajo: "Confirmar borrado del centro de trabajo",
  Historial: "Historial de reservas",
  tip_historialReservas: "Pulsa para ver el historial de reservas de la zona común",
  HoraInicio: "Hora inicio",
  HoraFin: "Hora fin",
  Administrador: "Administrador",
  Oficial: "Oficial",
  Auxiliar: "Auxiliar",
  CamposVacios: "Los campos en rojo no pueden estar vacios",
  SeleccionaUnaOpcion: "Selecciona una opción",
  AnadirUsuario: "Añadir Usuario",
  PersonasIncluidas: "Personas incluídas",
  Enteros: "Enteros",
  AnadirZonaComun: "Añadir zona común",
  DetalleZonaComun: "Detalle de la zona común",
  NombreZona: "Nombre zona",
  TipoZona: "Tipo de zona",
  NumeroPersonasReserva: "Número de personas permitidas por reserva",
  NumeroReservasPorDia: "Número de reservas por día/persona",
  HorizonteDeFechas: "Horizonte de Fechas",
  Aforo: "Aforo",
  SlotsSeguidos: "Slots seguidos permitidos",
  AperturaDiaSiguiente: "Abrir siguiente día de reservar a partir de las:",
  //LimitacionesReserva: "Limitaciones si Reserva Activa",
  LimitacionesReserva: "Permitir reservar si reserva Activa",
  MostrarPortalPiso: "Mostrar Portal, Piso, Letra del que ha reservado",
  NoPermitirReservarSiReserva: "No permitir reservar si Reserva Activa No consumida",
  MargenCancelacionReserva: "Margen de cancelación de reserva",
  NumeroPersonas: "Número de personas",
  SeleccionaNumpersonas: "Selecciona el número de personas",
  SeleccionaNumreservas: "Selecciona el número de reservas por persona/día",
  persona: "persona",
  personas: "personas",
  reserva: "reserva",
  reservas: "reservas",
  NoAplica: "No aplica",
  dias: "días",
  dia: "dia",
  SeleccionaHorizonte: "Selecciona el número de días",
  SeleccionaSlotsSeguidos: "Selecciona el número",
  Slot: "Slot",
  Slots: "Slots",
  SeleccionaPermitirReservarHora: "Selecciona la hora",
  limitaciones1: "SIN LIMITACIONES",
  limitaciones2: "Sólo a partir de la hora de aplicación, para el día siguiente",
  limitaciones3: "Sólo el mismo día de Reserva Activa hasta consumir las del día",
  limitaciones4: "Sólo puedes reservar con un minimo de horas de diferencia",
  limitaciones5: "Liberar el siguiente slot después de unas horas",
  SeleccionaLimitaSiReserva: "Selecciona la opción",
  LimiteHoraCancelacion: "Límite hora cancelación",
  Resetear: "Resetear",
  ResetearVotacionBody: "¿Estás seguro de que quieres resetear la votación?",
  VotacionResetearCorrectamente: "Votación reseteada correctamente",
  ZonaComunExiste:
    "La zona común ${var1} ya existe para la comunidad actual, por favor escribe otro nombre.",
  ControlStockReservas: "Control stock de reservas",
  visibleSoloAdmin: "VISIBLE SOLO POR EL ADMIN",
  limiteReservasUsuario: "Límite reservas/usuario",
  tipoControlStock: "Tipo de control de stock",
  permitirReservarSinStock: "Permitir reservar en la hora actual sin tener stock",
  consumirStockReservaActual: "Consumir del stock reservando en la hora actual",
  consumirStockAunCancelando: "Consumir del stock aunque se cancele la reserva",
  noConsumirStockDiaReserva: "No consumir stock el mismo día de la reserva",
  tipoControlStock1: "Por semana",
  tipoControlStock2: "Por mes",
  tipoControlStock3: "Por año",
  tipoControlStock4: "Por temporada",
  tipoControlStock5: "Por días",
  SeleccionaTipoControlStock: "Selecciona el tipo",
  FechaInicio: "Fecha inicio",
  FechaFin: "Fecha fin",
  noConsumirDesdeDia: "No consumir desde el día",
  noConsumirAPartir: "No consumir stock a partir de las",
  desdedia0: "Ningún día antes",
  desdedia1: "día antes",
  desdedia2: "días antes",
  SeleccionaDiasAntesSinConsumir: "Selecciona los días",
  ControlAbonos: "Control de Abonos",
  ControlInvitados: "Control de Invitados",
  AperturaAutomaticaPuerta: "Apertura automática puerta",
  GestionHorarios: "Gestión horarios",
  NumInvitadosPorReserva: "Número máximo de invitados por reserva",
  NumConvivientesPorReserva:
    "Número máximo de convivientes no nominales por zona y reserva. Esta opción aplica si el usuario no dispone de convivientes nominales",
  minutosApertura: "Activar apertura minutos antes de la hora de reserva",
  mantenerApertura: "Mantener apertura después de cierre minutos después",
  AperturaAutomatica: "Apertura automática de puerta",
  unCuartoDeHora: "1 cuarto de hora",
  mediaHora: "Media hora",
  tresCuartosHora: "3 cuartos de hora",
  unaHora: "1 hora",
  unaHoraYMedia: "1 Hora y Media",
  Horas: "Horas",
  HorasYMedia: "Horas y Media",
  HoraDeApertura: "Hora de apertura",
  HoraDeCierre: "Hora de cierre",
  TamanoSlot: 'Tamaño del "slot"',
  TodasComunidades: "Todas las comunidades",
  DireccionPrincipal: "Dirección principal",
  EstablecerHorarioFindes: "Establecer horario para fines de semana y festivos",
  CalendarioDiasFestivos: "Calendario de días festivos",
  DireccionRepetida: "La dirección ya está en el sistema",
  FestivosSeleccionados: "Días festivos",
  Enero: "Enero",
  en: "ene",
  Febrero: "Febrero",
  feb: "feb",
  Marzo: "Marzo",
  mar: "mar",
  Abril: "Abril",
  abr: "abr",
  Mayo: "Mayo",
  may: "may",
  Junio: "Junio",
  jun: "jun",
  Julio: "Julio",
  jul: "jul",
  Agosto: "Agosto",
  ago: "ago",
  Septiembre: "Septiembre",
  sep: "sep",
  Octubre: "Octubre",
  oct: "oct",
  Noviembre: "Noviembre",
  nov: "nov",
  Diciembre: "Diciembre",
  dic: "dic",
  Sabado: "Sábado",
  sab: "sab",
  Domingo: "Domingo",
  dom: "dom",
  Lunes: "Lunes",
  lun: "lun",
  Martes: "Martes",
  mar: "mar",
  Miercoles: "Miércoles",
  mier: "mier",
  Jueves: "Jueves",
  jue: "jue",
  Viernes: "Viernes",
  vie: "vie",
  AnadirHorario: "Añadir Horario",
  QuitarHorario: "Quitar Horario",
  HoraApertura: "Hora de Apertura",
  HoraCierre: "Hora de Cierre",
  Sabados: "Sábados",
  Domingos: "Domingos",
  Festivos: "Festivos",
  AnadirQuitarFestivos: "Añadir o Quitar Días Festivos",
  Fecha_Publicacion: "Fecha de publicación",
  errorValidacionStock1:
    "Si usas el control de stock de reservas, el valor del límite de reservas debe ser al menos 1",
  errorValidacionStock2:
    "fecha del inicio o fin de la temporada de control de stock no puede ser vacía",
  errorValidacionStock3:
    "fecha del inicio y fin de la temporada de control de stock no puede ser la misma",
  AnadirEmpleado: "Añadir Empleado",
  mensajeErrorHoras: "La hora de cierre no puede ser anterior a la hora de Apertura",
  confirmarBorradoProveedor: "¿Estás seguro de que quieres borrar el proveedor?",
  tituloConfirmarProveedor: "Confirmar borrado de proveedor",
  UsuariosCongreso: "Usuarios CNAF2024",
  Contrasena: "Contraseña",
  Abonos: "Tabla de Abonos",
  AbonosLuz: "Tabla de Abonos luz",
  ContratosAsoaciados: "Contratos asociados a la actividad",
  SinContratos: "Sin contratos asociados o el proveedor viene de conecta",
  ConContratos: "Con contratos asociados",
  TipoCobroAbono: "Tipo Cobro Abono",
  TipoCobroAbonoLuz: "Tipo Cobro Abono Luz",
  Abonado: "Abonado",
  CodigoAbono: "Código Abono",
  StockActual: "Stock Actual",
  Observaciones: "Observaciones",
  ReservasDisponiblesPorAbono: "Reservas disponible por abono",
  CosteEuros: "Coste en euros",
  TablaUsuariosAbono: "Tabla Usuarios Abono",
  TiposPagos: "Tipos de Pago",
  RecargaAbonoApp: "Recarga de abono app",
  RecargaAbonoLuz: "Recarga de abono luz",
  UsuariosAbonoLuz: "Usuarios abono de luz",
  TiposPagosLuz: "Tipos de Pago de Luz",
  HoraInicioLuz: "Hora inicio luz",
  HoraFinLuz: "Hora fin luz",
  ABIERTO: "ABIERTO",
  BLOQUEADO: "BLOQUEADO",
  NoBorrarActividadGremiosAsociados:
    "No se puede borrar la actividad porque tiene proveedores asociados",
  NoBorrarActividadIncidenciasAsociadas:
    "No se puede borrar la actividad porque tiene incidencias asociadas",
  TodoCorrecto: "Todo correcto",
  ContratoActividadExpiracion: "El contrato de la actividad está a punto de expirar",
  DetectadoProveedorEliminadoDespacho:
    "Desde c365 hemos detectado un proveedor eliminado en su despacho, este proveedor ha sido eliminado de la  actividad en la que estaba asociado, se le va a asociar al administrador",
  ActividadIncidenciasAbiertas:
    "La actividad tiene incidencia/s abiertas asociadas con el codigo : ",
  NoGremiosAsociados: "No hay proveedores asociados a la actividad",
  CentroDeTrabajoCreadoAnteriormente: "Ese centro de trabajo ya ha sido creado anteriormente",
  Completado: "Completado",
  UsuarioCreadoAnteriormente: "Ese usuario ya ha sido creado anteriormente",
  AnchoYAltoImagen: "El ancho de la imágen debe ser de 1054 pixeles y la altura de 444 pixeles",
  NoDatosDisponibles: "No hay datos disponibles",
  ReservasZonasComunes: "Reservas zonas comunes",
  GestionReservas: "Gestion de reservas",
  CodigoDebeSerNumero: "El código debe ser un numero",
  ElCodigo: "El código ",
  CoincideConOtraComunidad: " coincide con el de otra comunidad",
  VerContratos: "Ver contratos",
  ExisteEvaluacionNombreCambialo:
    "Existe ya una evaluación con este nombre, por favor cambia el nombre para poderla crear",
  PruebaActualizadaCorrectamente: "Prueba actualizada correctamente",
  TituloEvaluacion: "Titulo de la evaluación",
  CampoObligatorio: "este campo es obligatorio",
  FechaCreacion: "Fecha de creación",
  SeleccionAreasEvaluacion: "Selecciona las áreas que aplica la evaluación",
  SeleccionPruebasEvaluacion: "Selecciona las pruebas para esta evaluación",
  FechaInicio: "Fecha de inicio",
  FechaInicialEvaluacionAntesFinal:
    "La fecha inicial de la evaluacion debe ser antes de la fecha final",
  FechaFinEvaluacion: "Fecha fin evaluación",
  CambiarNombreDe: "Cambiar nombre de ",
  AnadirNombreCarpetaFichero: "Añade el nombre que quieres poner al fichero/carpeta",
  EnvioAvisoPrueba: "envio aviso prueba",
  NombreDeUsuario: "Nombre de usuario",
  SinComunidadAsociada: "Sin comunidad asociada",
  SinActividadAsociada: "Sin actividad asociada",
  Contratos: "Contratos",
  AnadirContrato: "Añadir contrato",
  DebesSeleccionarComunidadAnadirContrato:
    "Debes seleccionar una comunidad para añadir un contrato",
  DebesSeleccionarActividadAnadirContrato:
    "Debes seleccionar una actividad para añadir un contrato",
  DebesSeleccionarFechaInicioAnadirContrato:
    "Debes seleccionar una fecha de inicio para añadir un contrato",
  YaExisteContratoParaActividadEnComunidad:
    "Ya existe un contrato para esta actividad en esta comunidad",
  ContratoAnadidoCorrectamente: "Contrato añadido correctamente",
  ContratoEliminadoCorrectamente: "Contrato eliminado correctamente",
  Indefinido: "Indefinido",
  VerIncidenciasDe: "Ver incidencias de: ",
  DetalleProveedor: "Detalle del proveedor",
  ErrorBorrarArchivoCarpeta: "Error al borrar el archivo/carpeta: ",
  DocumentacionDeLaIncidencia: "Documentación de la incidencia",
  SinHorasRegistradas: "Sin horas registradas",
  DireccionRepetida: "La dirección está repetida",
  ValorEnterosNumerico: "El valor de enteros debe ser numérico",
  NoDejarEnterosVacios: "No puedes dejar los enteros vacíos",
  NoDejarDireccionVacia: "No puedes dejar la dirección vacía",
  RegistroRepresentanteCambiado: "Registro de representante cambiado.",
  ImagenUsuario: "Imagen del usuario",
  ImagenPorDefecto: "Imagen por defecto",
  AnadirAlMenosUnaOpcionVotacion: "Debe añadir al menos una opción de votación",
  NoAnadirOpcionRepetida: "No puedes añadir una opción repetida",
  NoAnadirOpcionVacia: "No puedes añadir una opción vacía",
  MostrarVotacionesBorradas: "Mostrar votaciones borradas",
  OcultarVotacionesBorradas: "Ocultar votaciones borradas",
  VotacionCerrada: "Votación cerrada",
  VotacionPublicada: "Votación Publicada",
  VotacionAbiertaPublicada: "Votacion abierta y publicada.",
  VerLog: "Ver log",
  AnadirBono: "Añadir nuevo bono",
  TituloBajaAbono: "Borrar abono",
  ConfirmarBajaAbono: "¿Estás seguro de que quieres borrar el abono?",
  TituloBajaTipoAbono: "Borrar tipo de abono",
  ConfirmarBajaTipoAbono: "¿Estás seguro de que quieres borrar el tipo de abono?",
  ProblemaNoPodidoEnviar: `Ha habido un problema y no se ha podido enviar`,
  AvisoEnviadoCorrectamenteA: "Aviso enviado correctamente a ",
  destinatarios: " destinatarios",
  FalloEnviarAviso: "Fallo al enviar el aviso: ",
  SoloPuedesSubirUnArchivo: "Sólo puedes subir 1 archivo",
  NoPuedesDejarTipoVacio: "No puedes dejar el tipo vacío",
  FalloEnElPago: `FALLO EN EL PAGO`,
  ErrorAbrirNuevaVentanaPermisosVentanas:
    "Error al abrir la nueva ventana, asegurate de tener permisos para abrir vetanas emergentes.",
  ContratoAceptadoPagosIniciados: "Contrato Aceptado y pagos iniciados.",
  ArrastraSueltaArchivosSubirlosCarpeta:
    "Arrastra y suelta aquí tus archivos para subirlos en la carpeta:",
  AnadirTipoBono: "Añadir tipo de abono",
  AnadirTipoBonoLuz: "Añadir tipo de abono de luz",
  AlertaGuardarCambios:
    'Has hecho cambios en la tabla, para que los cambios se guarden haz click en "Guardar".',
  ProveedorBajaEnComunidades: "El proveedor ha sido dado de baja en las comunidad / es",
  FInicio: "F. Inicio",
  FFin: "F. Fin",
  Contratar: "Contratar",
  AceptarTerminosContratacion: "Aceptar términos de contratación",
  VolverPrimerPaso: "Volver 1º Paso",
  DetalleComunidad: "Detalle Comunidad",
  Juntas: "Juntas",
  MensajeRecargaAbonos:
    "Ponte en contacto con soporte para facilitarnos los datos de la comunidad y el IBAN para configurar la plataforma de cobro",
  MensajeAperturaAutomatica:
    "Ponte en contacto con soporte para facilitarnos los datos de la comunidad y el IBAN para configurar plataforma de cobro para instalación de apertura puerta automática",
  Reservar: "Reservar zona",
  BloquearReservas: "Bloquear Zona Común",
  seleccionaSlot: "Selecciona el/los slot/s",
  SeleccionaUsuario: "Selecciona un usuario",
  comunidadActiva: "Comunidad activa",
  comunidadInactiva: "Esta comunidad no está de alta en C365",
  comunidadBloqueada: "Recuerda que esta comunidad está bloqueada",
  NumeroJunta: "Nº de Junta",
  Titulo: "Título",
  confirmarBorradoJunta: "Pulsa aceptar para borrar la junta",
  AnadirJunta: "Añadir Junta",
  FechaCelebracion: "Fecha de celebración",
  tip_BorrarJunta: "Borrar junta",
  NumeroAsistentes: "Número de asistentes",
  JuntasComunidad: "Juntas de la comunidad",
  AbrirConexion: "Abrir junta",
  CerrarConexion: "Cerrar junta",
  Abierta: "Abierta",
  Cerrada: "Cerrada",
  VideoComunidad: "Videoconferencia de la comunidad",
  Entrar: "Entrar",
  Junta: "Junta",
  TextoAyudaActividades1:
    "Selecciona las actividades sobre las que quieras que los vecinos de esta comunidad puedan enviar incidencias.",
  TextoAyudaActividades2:
    "Sólo los iconos de las actividades seleccionadas aparecerán en el móvil de los vecinos de esta comunidad.",
  TextoAyudaActividades3: "Los administradores recibirán TODAS las incidencias.",
  TextoAyudaActividades4:
    "Si quieres que , además del administrador, un proveedor reciba las incidencias de una actividad, asócialo desde el detalle de la actividad o del proveedor.",
  JuntaCerradaPestanaCerrandose:
    "La videoconferencia ha sido cerrada, la pestaña actual se cerrará en 10 segundos",
  HabilitarQR: "Habilitar QR",
  irAlEnlace: "Haz click aquí para copiar el enlace",
  tip_BorrarUsuarioWeb: "Borrar usuario creado desde la web",
  tip_BorrarUsuarioConecta: "No es posible borrar un usuario importado desde Conecta",
  cuerpoEliminarUsuario: "¿Deseas eliminar el usuario ",
  tituloEliminarUsuario: "Elliminar usuario ",
  ActivarUsuarioApp: "Activar la APP para el usuario",
  TextoActivarUsuarioApp:
    "Para activar el usuario en la APP por favor, escribe una contraseña y pulsa aceptar.",
  EmailVerificado: "Email verificado",
  ErrorVerificacionEmail: "Error al verificar email",
  ErrorBajaUsuario: "Error al dar de baja el usuario",
  ProcesoActivacionCompleto: "Proceso de activación de usuario completado",
  tituloBajaUsuarioAPP: "Desactivar el usuario",
  textoBajaUsuarioAPP: "Pulsa aceptar para dar de baja en la APP el usuario",
  ProcesoDesactivacionCompleto: "Proceso de desactivación de usuario completado",
  tip_usuarioActivado:
    "Usuario con acceso a la APP. Pulsa si quieres desactivar el acceso del usuario a la APP.",
  tip_usuarioDesactivado:
    "Usuario sin acceso a la APP. Pulsa si quieres activar el acceso del usuario a la APP.",
  amarillo_faltanDatosUsuario:
    "Falta/n el/los dato/s [${var1}], por favor, completa los datos de los usuarios de esta comunidad en tu ERP para que Conecta pueda volcarlos en C365",
  amarillo_usuarioNoRegistrado: "Este usuario todavía no está registrado en C365",
  amarillo_usuarioViviendaNoAsignada: "Este usuario no tiene vivienda asignada",
  amarillo_usuarioFalloFichaje:
    "Falta algún registro de salida en los fichajes de los días: ${var1}",
  amarillo_usuarioCamposVacios:
    "Faltan los datos :[${var1}], por favor, completa los datos de los usuarios de esta comunidad en tu ERP para que Conecta pueda volcarlos en C365",
  rojo_usuarioBloqueado: "Recuerda que este usuario está bloqueado",
  verde_usuarioCorrecto: "Usuario correcto",
  tip_publicarJunta: "Publicar/Despublicar la junta",
  confirmarPublicacionJunta: "Pulsa aceptar para publicar esta junta",
  tituloConfirmarPublicacion: "Publicar junta",
  confirmarDespublicacionJunta: "Pulsa aceptar para despublicar esta junta",
  tituloConfirmarDespublicacion: "Despublicar junta",
  JuntaPublicadaCorrectamente: "Junta publicada correctamente",
  errorBorrarIncidencia: "Error al publicar la junta",
  errorAbrirJunta: "Error al abrir la conexión",
  Asistentes: "Asistentes",
  asistentes: "asistentes",
  activado: "activado",
  desactivado: "desactivado",
  Asistente: "Asistente",
  asistente: "asistente",
  ControlAsistenciaUsuarios: "Control de asistencia de usuarios",
  Viviendas: "Viviendas",
  Vivienda: "Vivienda",
  Conexiones: "Nº Conexiones",
  HoraEntrada: "Última entrada",
  HoraSalida: "Última salida",
  TotalDuracion: "Total duración (minutos)",
  confirmarBorradoAsistente: "¿Estás seguro de que quieres borrar el asistente a la junta?",
  tituloConfirmarBorradoAsistente: "Confirmar borrado de asistente",
  RolBorradoCorrectamente: "Asistente borrado correctamente",
  tip_BorrarAsistente: "Pulsa para borrar un asistente",
  TipoAsistencia: "Tipo de Asistencia",
  NoCentroNoFichar: "No puedes fichar si no tienes un centro de trabajo asignado",
  asistirJunta: "Validar",
  CodigoCopiadoPortapapeles: "Código copiado al portapapeles",
  ProveedorLocalAnadido:
    "Desde C365 Conecta hemos detectado un nuevo proveedor en su despacho creado desde el módulo local. Puede comprobar si los datos son correctos y proceder a asociarlo a una actividad",
  tip_EnvioUsuarios: "Envio aviso a los usuarios previamente  seleccionados",
  tip_EnvioCodVec: "Envio email registro a los usuarios previamente seleccionados",
  NoSoyPropietario: "No soy propietario",
  placeholderObservaciones: "Por favor indica el motivo de asistencia a esta junta",
  ErrorGuardandoAsistencia: "Error guardando asistencia",
  AsistenciaRegistradaConExito: "Tu asistencia ha sido registrada correctamente.",
  ReservaCompleta: "Reserva completa",
  EmpleadoComunidad: "Empleado de la comunidad",
  JuntaAsociada: "Junta Asociada",
  ErrorFechasContrato: "La fecha de inicio no puede ser mayor que la fecha de fin",
  ErrorProveedorContrato: "El proveedor no puede ser vacío",
  ErrorFechaInicioMayor: "La fecha de inicio no puede ser mayor que la fecha de fin",
  SinJuntaAsociada: "Sin junta asociada",
  EmailNoValidoIntroduceCorrecto: "El Email no es valido, introduce uno correcto",
  verde_UsuarioRegistrado: "Usuario registrado en C365",
  verde_UsuarioNoRegistrado: "Usuario no registrado en C365",
  ErrorEmailVacio: "Para registrar al usuario es necesario que el usuario disponga de un email",
  EmailNoValido: "El email no es válido",
  Conexion: "Conexión",
  InactivarProveedorConContratos:
    " No se puede desactivar un proveedor con contratos asociados. Por favor, elimina los contratos antes de desactivar el proveedor",
  TiempoAcumulado: "Tiempo acumulado",
  QR: "Código QR",
  MostrarQR: "Mostrar QR",
  FormularioAsistencia: "Formulario de asistencia a la junta",
  EnlaceQRNoDisponible: "Código QR para el formulario de asistencia no habilitado.",
  verObservaciones: "Ver observaciones",
  ocultarObservaciones: "Ocultar observaciones",
  Enviar: "Enviar",
  tituloFormAvisosJuntas: "Enviar aviso de la junta",
  Asociados: "Contratos actuales",
  AsociadosHistoricos: "Contratos históricos",
  BorrarActividadContrato: "Eliminar contrato",
  tituloConfirmarContrato: "Confirmar eliminación de contrato",
  confirmarBorradoContrato: "¿Estás seguro de que quieres borrar el contrato?",
  ContratosHistoricos: "Contratos históricos",
  guardarEnPdf: "Descargar QR",
  notiArchi: "Notificación archivada correctamente",
  SinUsuarios: "Sin usuarios asociados al centro de trabajo",
  PlantillaConvocatoria: "Descargar Convocatoria",
  PlantillaActa: "Descargar Acta de Resolución",
  Convocatoria: "Convocatoria",
  Acta: "Acta",
  PrimeraConvocatoria: "Hora Primera Convocatoria",
  SegundaConvocatoria: "Hora Segunda Convocatoria",
  LugarCelebracion: "Lugar de celebración",
  ORDINARIA: "Ordinaria",
  EXTRAORDINARIA: "Extraordinaria",
  ZonaBien: "Zona comun correctamente configurada",
  ZonaFestivosMal:
    "La zona comun tiene marcado el checkbox de horario en festivos pero no tiene ningun horario configurado",
  ZonaHorariosBloqueados: "La zona común tiene horarios bloqueados",
  ordenDiaStandar1: "Aprobación, si procede, de las cuentas anuales.",
  ordenDiaStandar2: "Aprobación, si procede, del presupuesto ordinario de gastos e ingresos.",
  ordenDiaStandar3:
    "Relevo de cargos y nombramiento de la nueva Junta de Gobierno (Presidente, Vicepresidente, Vocales y Administrador)",
  ordenDiaStandar4: "Relevo de cargos en junta de gobierno",
  ordenDiaStandar5: "Ruegos y preguntas",
  ordenDiaStandar1Desc: "",
  ordenDiaStandar2Desc: "",
  Convocante: "Convocante",
  juntaCerradaPorApertura:
    "Se ha cerrado la videoconferencia previamente abierta para permitir la nueva conexión",
  ErrorJuntaNoPublicada: "No puedes abrir la conexión si la junta no está publicada",
  Abrir: "Abrir",
  Descripcion: "Descripción",
  Junta: "Junta",
  Nombre: "Nombre",
  Publicar: "Publicar",
  PublicarPorcentaje: "Publicar porcentaje",
  PublicarResultado: "Publicar resultado",
  Roles: "Roles",
  Vacio: "Vacio",
  Votode: "VOTO DE",
  hasioeliminao: "HA SIDO ELIMINADO",
  QRasistencia: "QR asistencia",
  CheckboxVotaciones: "Orden del día / Votaciones",
  TooltipVotaciones: "Incluir votaciones asociadas a la convocatoria de la junta",
  LibresDe: "Libres de",
  BLOQUEAEDA: "BLOQUEADA",
  SalaCompleta: "Sala completa",
  BloquearDesbloquear: "Bloquear/Desbloquear todo el día",
  TituloBorrarReserva: "Cancelar reserva",
  CuerpoBorrarReserva: "¿Estás seguro de que quieres cancelar la reserva?",
  camposCriticos:
    "El administrador ha cambiado datos criticos de la zona común, se ha recuperado el stock de ",
  camposCriticosSinAbono:
    "El administrador ha cambiado datos criticos de la zona común, se ha/han anulado tu/tus reserva/reservas: ",
  tituloCamposCriticos: "Recuperación de stock",
  tituloCamposCriticosSinAbono: "Anulación de reserva",
  AlertaNumPersonasAforo:
    "El número de personas permitido por reserva no puede ser mayor que el aforo permitido de la Zona: ${var1}",
  labelLimitaciones2: "Hora de aplicación",
  labelLimitaciones4: "Horas mínimas de diferencia",
  labelLimitaciones5: "Horas hasta liberación",
  seleccionaHoraLimitacion: "Selecciona hora",
  NoAsistido: "No ha asistido",
  AlertaSeCerraraConexion: "Al despublicar la junta también se cerrará la conexión.",
  Anulacion: "Se ha anulado la reserva",
  ParaElDia: "Para el día",
  para: "para",
  verDetalleEnvio: "Ver el detalle del envío",
  seHaReservado: "se ha reservado",
  reservaZonaComun: "Reserva de zona común",
  adminBloqueoReserva: "El administrador ha bloqueado la reserva",
  delDia: "del día",
  ContratoActividadExpirado: "El contrato de la actividad ha expirado",
  SinContrato: "Sin contrato",
  rolEncontradoUsu:
    "El rol de se puede borrar porque está asignado en usuarios en  las comunidades",
  rolEncontradoVota:
    "El rol de se puede borrar porque está asignado en votaciones en las comunidades",
  enLasVotaciones: " y a las votaciones",
  rolEncontradoJuntas:
    "El rol de se puede borrar porque está asignado en juntas en las comunidades",
  enLasJuntas: " y a las juntas",
  ningunaDeLasPropuestas: "Ninguna opción de las propuestas",
  resultadoEscrutinio: "Resultado del escrutinio",
  Escrutinio1: "⅓ de los votos",
  Escrutinio2: "Mayoría simple",
  Escrutinio3: "Mayoría reforzada",
  Escrutinio4: "⅗ partes de los asistentes",
  Escrutinio5: "Unanimidad",
  tooltipEscrutinio2: "Más de la mitad de los votos o más del 50% de la cuota de participación",
  tooltipEscrutinio3:
    "⅗ partes. Se suman los votos de los no asistentes a los votos de la mayoría, y éstos tienen 1 mes para revocar su voto. No se puede generar el acta hasta que haya pasado el mes",
  NaturalezaVotacion: "Naturaleza de la votación",
  ResultadoVotacion: "Resultado de la votación",
  Aprobada: "Aprobada",
  NoAprobada: "No aprobada",
  ConclusionesVotacion: "Conclusiones de la votación",
  Conclusiones: "Conclusiones de la votación",
  configuracionCorrecta: "Configuración correcta",
  contratoActivo: "Contrato activo",
  contratoCaducado: "Contrato caducado, a el proveedor le seguirán llegando avisos",
  Nombre: "Nombre",
  Apellido: "Apellido",
  Email: "Email",
  DNI: "NIF/NIE/CIF",
  Telefono: "Teléfono",
  BuscarEnTodos: "Buscar en todos los campos",
  AnularReserva: "Cancelar",
  ErrorCancelarReserva: "No se puede cancelar una reserva cancelada / bloqueada",
  DetalleNotificacion: "Detalle de la notificación",
  DocumentacionNotificacion: "Documentación Notificación",
  IrDetalleIncidencia: "Ver incidencia relacionada",
  CuerpoBloqueoNotificacion:
    "Al borrar la notificación ninguno de los usuarios que la han recibido podrá acceder y visualizarla",
  TituloBloqueoNotificacion: "Confirmar borrado de la notificación",
  Leida: "Leída",
  FechaLectura: "Fecha de lectura",
  IdUsuario: "Id Usuario",
  DispositivosEnviados: "Dispositivos enviados",
  DispositivosError: "Dispositivos con error",
  marcarNotificacionLeida: "Marcar o desmarcar la notificacion como leída",
  archivarNotificacion: "Archivar o desarchivar la notificación",
  desmarcarNotificacionLeida: 'Para desmarcar la notificación como leida pulsa "ACEPTAR"',
  marcarNotificacionLeida: 'Para marcar la notificación como leida pulsa "ACEPTAR"',
  tituloNotificacionLeida: "Marcar Notificación LEIDA/NO LEIDA",
  recuperarNotificacion: 'Para recuperar la notificación pulsa "ACEPTAR"',
  archivarNotificacion: 'Para archivar la notificación pulsa "ACEPTAR"',
  tituloArchivarNotificaciones: "Archivar notificaciones",
  marcadaLeida: "Marcada o desmarcada como leída",
  marcadaArchivada: "Marcada o desmarcada como Archivada",
  sinNombre: "Sin nombre",
  sinApellido: "Sin apellidos",
  LEIDA: "LEÍDA",
  NOLEIDA: "NO LEÍDA",
  TostaNotificacionArchivada: "La notificación ha sido archivada",
  TostaNotificacionDesarchivada: "La notificación ya no está archivada",
  TostaNotificacionLeida: "La notificación ha sido marcada como leída",
  TostaNotificacionNoLeida: "La notificación ha sido marcada como no leída",
  TituloDispositivosUsuario: "Tabla de dispositivos del usuario ",
  conERROR: " con ERROR",
  TextoNotificacion: "Texto notificación",
  UrlAdjunta: "Url adjunta",
  envioDispositivoCorrecto: "El aviso ha sido enviado y recibido correctamente en el dispositivo",
  envioDispositivoIncorrecto:
    "El aviso no ha podido ser enviado y no ha sido recibido en el dispositivo",
  errorEnvioNoti:
    "No se ha podido enviar el aviso ni ha sido recibido en todos los posibles dispositivos (",
  errorEnvioNoti1: "No se ha podido enviar el aviso ni ha sido recibido en ",
  errorEnvioNoti2: " dispositivos de ",
  errorEnvioNoti3: " enviados",
  exitoEnvioDispositivos: "Todos los dispositivos han recibido el aviso correctamente",
  NavegarJunta: "Navegar a la junta",
  tip_asistirJunta: "Asistencia",
  tituloAsistencia: "Detalle de la asistencia",
  datosAsistencia: "Datos de la asistencia",
  //ASISTENCIA_REMOTA_APP: "REMOTA - APP",
  ASISTENCIA_REMOTA_APP_DELEGADO: "REMOTA - APP DELEGADO",
  ASISTENCIA_REMOTA: "REMOTA",
  ASISTENCIA_REMOTA_ENLACE_VIDEO: "REMOTA - ENLACE VIDEO",
  ENLACE: "ENLACE",
  APP: "APP",
  ASISTENCIA_FISICA_QR: "FISICA QR",
  ASISTENCIA_FISICA_FORMULARIO: "FISICA FORMULARIO",
  ASISTENCIA_FISICA_FORMULARIO_NO_PROPIETARIO: "FÍSICA FORMULARIO + NO PROPIETARIO",
  ASISTENCIA_FISICA: "FISICA",
  ASISTENCIA_DELEGADA: "DELEGADA",
  ASISTENCIA_: `\u00A0`,
  rojo_usuarioDesactivado: "Este usuario todavía no está registrado en C365",
  ErrorRecuperandoDatosAsistente: "Error mientras se recuperaban los datos de asistente",
  DelegadoEn: "Delegado en",
  usuarioHaVotado: "El usuario ha votado",
  NuevoAsistente: "Nuevo asistente",
  NoEstasRegistradoAPP: "No estás registrado en la APP",
  usuarioSinVotar: "El usuario ha asistido pero no ha votado",
  ErrorSeleccionOpcionVotacion:
    "Has seleccionado la naturaleza de la votación unanimidad pero no has seleccionado ninguna opción",
  ErrorSeleccionResultadoVotacion:
    "Has seleccionado la naturaleza de la votación unanimidad pero no has seleccionado ningún resultado",
  Abs: "Abs",
  JuntaSinConexion: "Abrir conexión sin junta",
  AutoRellenarDNI:
    "Introduce tu DNI para auto-rellenar tus datos si estas en la base de datos de la comunidad",
  UsuarioNoRegistradoEnApp:
    "Te hemos encontrado en la base de datos de la comunidad, pero no estas registrado en la APP ¿Quieres que te enviemos un email con los pasos necesarios para  que puedas darte de alta?",
  TipoVotacion: "Tipo de votación",
  QuitarAsistencia:
    "Vas a quitar la asistencia a la junta, se borrarán todos los votos del usuario asociados a la junta",
  VerFichajes: "Ver Fichajes",
  PRESIDENTE: "Presidente",
  FIRMANTES: "Los abajo firmantes",
  SeleccionaConvocante: "Selecciona convocante",
  PrimC: "1ª conv.",
  SegC: "2ª conv.",
  TipoJunta: "Tipo de asistencia",
  TituloConexion: "Título de la conexión",
  AbrirEnlacePestanaNueva: "Abrir enlace en otra pestaña",
  Paso: "Paso",
  OcultarTutorial: "Ocultar tutorial",
  VerTutorial: "Ver tutorial",
  Destinatarios: "Destinatarios",
  Crear: "Crear",
  AQuienVaDirigida: "¿A quién va dirigida?",
  CuandoYDonde: "¿Cuándo y dónde?",
  TipRolesJuntas: "(Por defecto todos los propietarios, selecciona uno o varios roles)",
  Servicios: "Servicios",
  ProcesoAltaComunidad: "Proceso de alta de Comunidad",
  ListaContratos: "Lista de contratos",
  IrADocumentacion: "Ir a la documentación de la junta",
  EstadoActual: "Estado actual",
  Publicada: "Publicada",
  NoPublicada: "Sin publicar",
  NoPublicar: "Despublicar",
  AsistentesConvocados: "Asistentes convocados",
  AsistentesConfirmados: "Asistentes confirmados",
  GenerarQR: "Generar QR",
  QRdeLaJunta: "Código QR de la Junta",
  TipoConexion: "Tipo de conexion",
  DeshabilitarControlAsistencia: "Deshabilitar Control de Asistencia",
  HabilitarControlAsistencia: "Habilitar Control de Asistencia",
  Habilitado: "Habilitado",
  Deshabilitado: "Deshabilitado",
  TituloVotacion: "Título de la votación",
  EsParaJunta: "¿Es para una Junta?",
  TipJuntasVotaciones: "Si es para una junta selecciona una de la lista",
  TipoVotacion: "Tipo de votación",
  OpcionesVotacion: "Opciones de votación",
  NingunaDeLasOpciones: "Ninguna de las opciones",
  CriteriosVotacion: "Opción mayoritaria",
  AbrirVotacion: "Abrir votación",
  CerrarVotacion: "Cerrar votación",
  VotacionAbierta: "Votación abierta",
  VotacionCerrada: "Votación cerrada",
  NoPublicarResultado: "Despublicar resultado",
  PublicarResultado: "Publicar resultado",
  ResultadoNoPublicado: "Resultado no publicado",
  ResultadoNoPublicado: "Resultado publicado",
  NoPublicarPorcentaje: "No mostrar porcentaje",
  PublicarPorcentaje: "Mostrar porcentaje",
  PorcentajeNoPublicado: "Mostrando porcentaje",
  PorcentajePublicado: "No mostrando porcentaje ",
  Votos: "Votos",
  totalVotosActa: "Total votos : ",
  sumaTotalHoras: "Suma total de horas",
  QRfichar: "QR para fichar",
  enPrep: "en",
  contratoanadir: "Añadir contrato",
  contratoanadira: "Añadir contrato a ",
  OcultarContratosCaducados: "Ocultar contratos finalizados",
  MostrarContratosCaducados: "Mostrar contratos finalizados",
  tituloEnvioAvisoVotacion: "Comunicado sobre votación",
  ContratoHisotrico: "Contrato expirado",
  RegistroDeContratros: "Registro de contratos",
  OcultarIconosApp: "Visualización funcionalidades en la app del móvil para esta comunidad",
  VerJuntas: "Juntas",
  Seguro: "Seguro",
  Energia: "Energía",
  Mantenimiento: "Mantenimiento",
  Reformas: "Reformas",
  VerFacturas: "Ver facturas",
  VerTutorial: "Ver tutorial",
  RegistroIncidencias: "Registro de incidencia",
  Compartida: "Compartida",
  SinCompartir: "Sin compartir",
  DocumentacionDeLaIncidenciaInfo:
    "Subir, descargar o visualizar la documentación de la incidencia",
  BotonSOS: "Botón SOS",
  NoPublicarIncidencia: "No se puede compartir una incidencia no publicada,cerrada o cancelada",
  RegistradoPor: "Registrado por",
  NoEliminarOpcionNinguna: "No puedes eliminar la opción de ninguna de las propuestas",
  ActividadesTitle: "Actividades",
  CiaSegurosComunidad: "Cia de seguros de la comunidad",
  ListadoDeActividades: "Listado de actividades",
  DetalleComunidad: "Detalle de la comunidad",
  OcultoAPP: "Oculto en la APP",
  VisibleAPP: "Visible en la APP",
  ZonasComunes2: "Reservas",
  PermisosDeAcceso: "Permisos de acceso",
  NIFNIE: "NIF / NIE / CIF",
  DocumentacionInfo: "Descargar o visualizar la documentación de la comunidad",
  CentrosTrabajoNavegar: "Navegar a los centros de trabajo",
  anadirDirecciones: "Añadir direcciones",
  anadirDireccion: "Añadir dirección",
  funcionesNoDisponibles:
    "* Algunas funcionales no estarán disponibles porque el usuario no se ha registrado en la app",
  solicitarConfirmacionEmail: "Solicitar Confirmación Cuenta email",
  restablecerContrasena: "Restablecer contraseña",
  seleccionaTipo: "Selecciona el tipo",
  UsuarioNoCreado: "El usuario no ha sido creado, por favor, inténtalo de nuevo",
  cambiarContrasenaUser: "Cambiar contraseña de usuario",
  VerificarEmail: "Verificar email",
  EliminarUser: "Eliminar usuario",
  verRegistroHorario: "Ver registro horario",
  verFichajesUsuario: "Ver los fichajes de usuario",
  EstadoActual: "Estado actual",
  CentrosAsignadosDetalle: "Centros de trabajo asignados",
  planContratadoActivo: "Plan contratado activo:",
  actualizarModificarPack: "Actualizar / Modificar pack",
  darDeBaja: "Dar de baja",
  descargarPrecios: "Descargar precios",
  Plan: "Plan",
  iban: "IBAN",
  numeroIban: "Número de IBAN",
  Vecinos: "Vecinos",
  Periodicidad: "Periodicidad",
  Precio: "Precio",
  ViviendasEnComunidad: "Viviendas en la comunidad",
  EmailVerificado: "Email verificado",
  envioConfirmacionEmail: "Enviar correo de confirmacion",
  AvisosP: "Enviar notificacion / email al usuario.",
  FicharDesactivado: "Fichar desactivado",
  AsociarUsuariosCentroTrabajo: "Asociar usuarios al centro de trabajo",
  IncidenciasRecibiraUsuario: "De qué comunidades recibirá incidencias este usuario administrador",
  AsignalRolPorComunidad: "Asignar rol por comunidad",
  DocumentacionInfoUsers: "Enviar documentación confidencial a los usuarios",
  otorgarPermisosUsuarios: "Otorgar permisos a los usuarios",
  RecibirAverias: "Recibir averías",
  TipoDeConvocatoria: "Tipo de convocatoria",
  votoDelegado: "Voto delegado",
  Leida: "Leída",
  SinLeer: "Sin leer",
  ErrorSegundaConvocatoria: "La segunda convocatoria no puede ser anterior a la primera",
  Archivada: "Archivada",
  SinArchivar: "Sin archivar",
  TelefonoNoValido: "El formato del teléfono no es válido, por favor introduce uno correcto.",
  ViviendasSolicitadasEnComunidad: "Vivienda solicitada en el registro en la APP",
  usuarioSinInstancia: "El usuario se ha registrado desde la app pero no tiene instancia asociada",
  adjudicarInstancia: "Adjudicar instancia al usuario",
  viviendaSolicitada: "Solicitada en registro APP",
  ResultadoEnviosNotificaciones: "Resultado de los envíos de notificaciones",
  usuarioNoEncontradoEnComunidad:
    "Usuario no encontrado en la base de datos de la comunidad, Si no erees propietario marca el check de no soy propietario",
  JuntaYaBorrada: "La junta ya ha sido borrada",
  VisualizarAPP: "Ver Iconos",
  VerIconosAPP: "Ver / Ocultar los iconos en la APP",
  VisualizarAPPIconos: "Visualización funcionalidades en la app del móvil para esta comunidad",
  JuntaYaBorradaQR: "La junta ya ha sido borrada, no se puede generar el QR",
  JuntaYaBorradaPublicar: "La junta ya ha sido borrada, no se puede publicar",
  ConfigurarProveedores:
    "Completa los datos de tus proveedores contratados, para gestionar averías y siniestros.",
  CrearCentrosTrabajo:
    "Habilita a los empleados para que puedan fichar (si tienes contratado el Pack Control Horario).",
  ConfigurarProveedores2: "Configuración  proveedores",
  MarcarActividades2: "Marcar actividades",
  AsociarProveedorAactividad: "Asociar proveedor a actividad",
  SubirDocumentos: "Subir documentos",
  CrearJuntasYvotaciones: "Crear juntas y votaciones",
  AñadirZonasComunes: "Añadir zonas comunes",
  CrearCentrosDeTrabajo: "Crear centros de trabajo",
  RegistroDeUsuarios: "Registro de usuarios",
  CrearZonas: "Crear zonas comunes",
  MostrarEnlace: "Mostrar enlace",
  OcultarEnlace: "Ocultar enlace",
  CrearJuntas: "Crear juntas",
  CrearVotaciones: "Crear votaciones",
  AñadirZonasComunes2: "Crear centros de trabajo ",
  Totales: "Totales",
  VotoGuardadoCorrectamente: "Voto guardado correctamente",
  AnadirVotoModificar: "Añadir / Modificar voto",
  SeleccionaUsuarioVotar: "Selecciona un usuario para votar",
  SeleccionaOpcionVotar: "Selecciona una opción para votar",
  ErrorAdjudicandoInstancia: "Ha ocurrido un error adjudicando la instancia.",
  CodigoAdministracionEnUso: "El código de administración ya está en uso",
  CodigoAdminitradorNoValido:
    "El valor debe contener exactamente tres letras seguidas por un número.",
  usuarioConInstanciaSinAdjudicar: "El usuario tiene una instancia sin adjudicar",
  BloquearZona: "Bloquear zona",
  errorBorrarIncidencia2: "Solo se pueden eliminar incidencias con el estado CERRADA o CANCELADA",
  ReservaCanceladaCorrectamente: "La reserva se ha cancelado correctamente",
  Contacto: "Persona de contacto",
  enviadoscorrectosCabecera: "Notificaciónes enviadas correctamente",
  enviadosIncorrectosCabecera: "Notificaciónes enviadas con error",
  enviadosTotalesCabecera: "Notificaciónes enviadas",
  enviadoTotalesCabeceraTexto: "Total de notificaciones enviadas",
  Calle: "Calle",
  ReferenciaExt: "Referencia",
  CNAEDescripcion: "Descripción CNAE",
  DatosContratoGuardadosCorrectamente: "Datos del contrato guardados correctamente",
  Propiedades: "Propiedades",
  PropietariosVecinos: "Propietarios/Inquilinos/Empresas/Rep.Legales",
  DestinatarioConsulta: "Destinatario de la consulta",
  SlotReservadoDesbloquealo:
    "El slot está bloqueado. Si quieres reservarlo primero tienes que desbloquearlo.",
  CreadoDesde: "Creado desde",
  ProveedorRecibiraEmail: "* El proveedor siempre recibirá un correo con la incidencia",
  EnviarNotificaciones: "Enviar notificaciones",
  enviarEmails: "Enviar emails",
};

export const tableLocation_es = {
  pagination: {
    labelRowsSelect: "Filas por página",
    labelDisplayedRows: "{from}-{to} de {count}",
    firstAriaLabel: "Primera página",
    firstTooltip: "Primera página",
    previousAriaLabel: "Página anterior",
    previousTooltip: "Página anterior",
    nextAriaLabel: "Siguiente página",
    nextTooltip: "Siguiente página",
    lastAriaLabel: "Última página",
    lastTooltip: "Última página",
  },
  toolbar: {
    searchTooltip: "Buscar",
    searchPlaceholder: "Buscar",
    nRowsSelected: "{0} filas(s) seleccionada(s)",
  },
  header: {
    actions: "Acciones",
  },
  body: {
    emptyDataSourceMessage: "No hay datos disponibles",
    filterRow: {
      filterTooltip: "Filtrar",
    },
    editRow: {
      saveTooltip: "Guardar",
      cancelTooltip: "Cancelar",
      deleteText: "¿Estás seguro de querer borrar esta fila?",
    },
    addTooltip: "Agregar fila",
    deleteTooltip: "Borrar fila",
  },
  edit: {
    saveTooltip: "Guardar",
    cancelTooltip: "Cancelar",
    deleteText: "¿Estás seguro de querer borrar esta fila?",
  },
  group: {
    deleteTooltip: "Borrar grupo",
    editTooltip: "Editar grupo",
    addTooltip: "Agregar grupo",
  },
};
