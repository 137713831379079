import { child, get, push, update } from "firebase/database";
import * as ROUTES from "../../constants/routes";
import moment from "moment";
import { Document, Packer, Paragraph, TextRun } from "docx";
import { getDireccionCompleta } from "app/components/helpers/Various";
import { loadAdminUsers } from "app/views/adminDesk/FuncionesAdmin";
import { traerUsuario } from "app/views/votaciones/FuncionesVotaciones";
import { object } from "prop-types";
import { ar } from "date-fns/locale";
export const getListaTiposAsistencia = (diccionario) => {
  return [
    /* { label: diccionario["ASISTENCIA_REMOTA_APP"], value: "REMOTA_APP", isDisabled:true },
    { label: diccionario["ASISTENCIA_REMOTA_APP_DELEGADO"], value: "REMOTA_APP_DELEGADO", isDisabled:true },
    { label: diccionario["ASISTENCIA_REMOTA_ENLACE_VIDEO"], value: "REMOTA_ENLACE_VIDEO", isDisabled:true },*/
    {
      label: diccionario["ASISTENCIA_FISICA"],
      value: "FISICA",
      isDisabled: false,
    },
    {
      label: diccionario["ASISTENCIA_DELEGADA"],
      value: "DELEGADA",
      isDisabled: false,
    },
    {
      label: diccionario["ASISTENCIA_FISICA_QR"],
      value: "FISICA_QR",
      isDisabled: true,
    },
    {
      label: diccionario["ASISTENCIA_FISICA_FORMULARIO"],
      value: "FISICA_FORMULARIO",
      isDisabled: true,
    },
    {
      label: diccionario["ASISTENCIA_FISICA_FORMULARIO_NO_PROPIETARIO"],
      value: "FISICA_FORMULARIO_NO_PROPIETARIO",
      isDisabled: true,
    },

    { label: diccionario["ASISTENCIA_"], value: "", isDisabled: false },
  ];
};

export const openNewTabJitsi = (e, IdComu, IdJunta = null, tituloJunta = "", numeroJunta = "") => {
  const routeVideoJitsi = ROUTES.VIDEO_JITSI;
  e.preventDefault();
  const newTabUrl = `${window.location.origin}${routeVideoJitsi}/${IdComu}/${IdJunta}/${numeroJunta}`; // Replace 'special-route' with your desired route
  window.open(newTabUrl, "_blank", "noopener,noreferrer");
};

export const cerrarSesionAsistente = async (firebase, idComunidad, idJunta, idSesionUsuario) => {
  const refAsistentes = firebase.getReferencia(`Comunidad/${idComunidad}/Juntas/${idJunta}/Asistentes`, null);

  const snapshot = await get(refAsistentes);
  const asistentes = snapshot.val();
  let datosAsistentes = [];
  datosAsistentes =
    asistentes &&
    Object.keys(asistentes).map((key) => ({
      idInterno: key,
      ...asistentes[key],
    }));
  // Busco por id de sesión
  const asistenteEnJunta = datosAsistentes
    ? datosAsistentes.filter((asistente) => asistente.idSesionUsuario === idSesionUsuario)
    : [];
  if (asistenteEnJunta.length > 0) {
    // Lo modifico???
    asistenteEnJunta.map((asist) => {
      const horaEntrada = moment(asist.Entrada);
      const cierre = Date.now();
      const horaCierre = moment(cierre);
      const Tiempo = horaCierre.diff(horaEntrada, "minutes");
      const tiempoAcumulado = Number(Tiempo) + Number(asist.TiempoAcumulado);

      const refAsistenteMod = firebase.getReferencia(
        `Comunidad/${idComunidad}/Juntas/${idJunta}/Asistentes/${asist.idInterno}`,
        null
      );
      const objAsistenteMod = {
        TiempoAcumulado: tiempoAcumulado,
        Salida: cierre,
      };

      update(refAsistenteMod, objAsistenteMod);
    });
  }
};
export const insertaAsistente = async (
  firebase,
  idComunidad,
  idJunta,
  usuario,
  nombreEnVideoConferencia = "",
  idSesionUsuario,
  tipoConexion
) => {
  // Sólo inserto asistentes si es dentro de una junta
  if (idJunta!=='null') {
    const refAsistentes = firebase.getReferencia(`Comunidad/${idComunidad}/Juntas/${idJunta}/Asistentes`, null);

    const snapshot = await get(refAsistentes);
    const asistentes = snapshot.val();
    let datosAsistentes = [];
    datosAsistentes =
      asistentes &&
      Object.keys(asistentes).map((key) => ({
        idInterno: key,
        ...asistentes[key],
      }));

    if (usuario) {
      const idUsuario = usuario.idInterno;

      const asistenteEnJunta = datosAsistentes
        ? datosAsistentes.filter((asistente) => asistente.DNI === idUsuario)
        : [];

      if (asistenteEnJunta.length > 0) {
        // Modifico
        asistenteEnJunta.map((asist) => {
          const refAsistenteMod = firebase.getReferencia(
            `Comunidad/${idComunidad}/Juntas/${idJunta}/Asistentes/${asist.idInterno}`,
            null
          );
          const objAsistenteMod = {
            idSesionUsuario: idSesionUsuario,
            Nombre: usuario.nombre,
            Apellido: usuario.apellido,
            DNI: usuario.DNI,
            Email: usuario.email,
            Direcciones: usuario.direcciones ? usuario.direcciones : [],
            TiempoAcumulado: asist.TiempoAcumulado,
            TipoAsistencia: "REMOTA",
            TipoConexion: tipoConexion,
            Entrada: Date.now(),
            Salida: "",
            Conexiones: asist.Conexiones ? asist.Conexiones + 1 : 1,
          };
          update(refAsistenteMod, objAsistenteMod);
        });
      } else {
        // Lo inserto
        const objAsistente = {
          idSesionUsuario: idSesionUsuario,
          Nombre: usuario.nombre,
          Apellido: usuario.apellido,
          DNI: usuario.DNI,
          Email: usuario.email,
          Direcciones: usuario.direcciones ? usuario.direcciones : [],
          Observaciones: "",
          TiempoAcumulado: 0,
          TipoAsistencia: "REMOTA",
          TipoConexion: tipoConexion,
          Entrada: Date.now(),
          Salida: "",
          Conexiones: 1,
        };
        push(refAsistentes, objAsistente);
      }
    } else {
      const asistenteEnJunta = datosAsistentes
        ? datosAsistentes.filter(
            (asistente) => asistente.Email === nombreEnVideoConferencia || asistente.Nombre === nombreEnVideoConferencia
          )
        : [];

      if (asistenteEnJunta.length > 0) {
        // Modifico
        asistenteEnJunta.map((asist) => {
          const refAsistenteMod = firebase.getReferencia(
            `Comunidad/${idComunidad}/Juntas/${idJunta}/Asistentes/${asist.idInterno}`,
            null
          );
          const objAsistenteMod = {
            idSesionUsuario: idSesionUsuario,
            Nombre: nombreEnVideoConferencia,
            Apellido: "",
            DNI: nombreEnVideoConferencia,
            Email: nombreEnVideoConferencia,
            Entrada: Date.now(),
            Salida: "",
            Conexiones: asist.Conexiones ? asist.Conexiones + 1 : 1,
            TipoConexion: tipoConexion,
          };
          update(refAsistenteMod, objAsistenteMod);
        });
      } else {
        // Lo inserto
        const objAsistente = {
          idSesionUsuario: idSesionUsuario,
          Nombre: nombreEnVideoConferencia,
          Apellido: "",
          DNI: "",
          Email: "",
          Direcciones: [],
          Observaciones: "Usuario sin registrar",
          TiempoAcumulado: 0,
          TipoAsistencia: "REMOTA",
          TipoConexion: tipoConexion,
          Entrada: Date.now(),
          Salida: "",
          Conexiones: 1,
        };
        push(refAsistentes, objAsistente);
      }
    }
  }
};

export const getTituloJunta = async (firebase, idComunidad, idJunta) => {
  const snapshot = await get(firebase.getReferencia(`Comunidad/${idComunidad}/Juntas/${idJunta}/Titulo`, null));
  const Tit = snapshot.val();
  return Tit;
};

export const getJunta = async (firebase, idComunidad, idJunta) => {
  const snapshot = await get(firebase.getReferencia(`Comunidad/${idComunidad}/Juntas/${idJunta}`, null));
  const Junta = snapshot.val();
  return Junta;
};

const normalizeString = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const yaExisteNumero = async (firebase, idComunidad, numeroComprobar, idPropio = null) => {
  const snapshot = await get(firebase.getReferencia(`Comunidad/${idComunidad}/Juntas`, null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    childData.idInterno = childKey;
    datos.push(childData);
  });

  let otros = null;
  if (!idPropio) {
    otros = datos.filter(
      (junta) => normalizeString(junta.Numero.toLowerCase()) === normalizeString(numeroComprobar.toLowerCase())
    );
  } else {
    otros = datos.filter(
      (junta) =>
        normalizeString(junta.Numero.toLowerCase()) === normalizeString(numeroComprobar.toLowerCase()) &&
        idPropio != junta.idInterno
    );
  }

  return otros.length > 0 ? true : false;
};
export const formatFecha = (fecha) => {
  let retFecha = "";
  if (fecha) {
    fecha = new Date(fecha).toLocaleDateString("en-GB").replace(/\//g, "-");
  }
  return fecha;
};

export const cargarOpcionesJuntasComunidad = async (firebase, IdComunidad) => {
  try {
    const snapshot = await get(firebase.getReferencia(`Comunidad/${IdComunidad}/Juntas`, null));
    let datos = [];
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      let childData = childSnapshot.val();

      childData.idInterno = childKey;

      if (!childData.Borrado) {
        if (!IdComunidad || (IdComunidad && childData.Comunidad === IdComunidad)) {
          datos.push({
            label: childData.Numero + " - " + childData.Titulo,
            value: childKey,
          });
        }
      }
    });
    return datos;
  } catch (error) {
    console.error("Error al cargar las Juntas", error);
  }
};

export const cargarJuntasComunidad = async (firebase, IdComunidad) => {
  try {
    const snapshot = await get(firebase.getReferencia(`Comunidad/${IdComunidad}/Juntas`, null));
    let datos = [];
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      let childData = childSnapshot.val();
      childData.FechaCelebracionFormateada = formatFecha(childData.FechaCelebracion);
      childData.NumeroAsistentes = childData.Asistentes ? Object.keys(childData.Asistentes).length : 0;
      childData.idInterno = childKey;

      if (!childData.Borrado) {
        if (!IdComunidad || (IdComunidad && childData.Comunidad === IdComunidad)) {
          datos.push(childData);
        }
      }
    });
    return datos;
  } catch (error) {
    console.error("Error al cargar las Juntas", error);
  }
};

export const yaExisteTitulo = async (firebase, idComunidad, tituloComprobar, idPropio = null) => {
  const snapshot = await get(firebase.getReferencia(`Comunidad/${idComunidad}/Juntas`, null));
  let datos = [];
  snapshot.forEach((childSnapshot) => {
    const childKey = childSnapshot.key;
    const childData = childSnapshot.val();
    childData.idInterno = childKey;
    datos.push(childData);
  });

  let otros = null;
  if (!idPropio) {
    otros = datos.filter((junta) => {
      if (normalizeString(junta.Titulo.toLowerCase()) === normalizeString(tituloComprobar.toLowerCase())) {
        return junta;
      }
    });
  } else {
    otros = datos.filter(
      (junta) =>
        normalizeString(junta.Titulo.toLowerCase()) === normalizeString(tituloComprobar.toLowerCase()) &&
        idPropio != junta.idInterno
    );
  }

  return otros.length > 0 ? true : false;
};

export const generateWordDocument = (title, content) => {
  // Create a new document
  const doc = new Document({
    sections: [
      {
        properties: {},
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: title,
                bold: true,
                size: 32, // 16 * 2 (in half-points)
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: content,
                size: 24, // 12 * 2 (in half-points)
              }),
            ],
          }),
        ],
      },
    ],
  });

  // Convert the document to a Blob
  return Packer.toBlob(doc);
};

export const cargarRoles = async (firebase, setListaRoles) => {
  try {
    const snapshot = await get(firebase.getReferencia("Roles2", null));
    let datos = [];
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      childData.idInterno = childKey;
      datos.push({
        label: childData.Nombre,
        value: childData.Nombre,
      });
    });
    setListaRoles(datos);
  } catch (error) {}
};
export const traerPropietariosComunidad = async (firebase, idComunidad = null) => {
  try {
    const snapshot = await get(firebase.getReferencia("Usuarios", null));

    const dirRef = idComunidad ? `Comunidad/${idComunidad}` : "Comunidad";
    const snapshotComunidades = await get(firebase.getReferencia(dirRef));
    let comuComus = [];

    if (!idComunidad) {
      snapshotComunidades.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        let childData = childSnapshot.val();
        childData.idInterno = childKey;
        comuComus.push(childData);
      });
    } else {
      let childData = snapshotComunidades.val();
      childData.idInterno = idComunidad;
      comuComus.push(childData);
    }

    let datos = [];
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      childData.idInterno = childKey;

      const nombreProp =
        childData.Roles && childData.Roles.length > 0
          ? childData.Nombre + " " + childData.Apellido + " " + " | " + childData.Roles.join(",")
          : childData.Nombre + " " + childData.Apellido;
      let strCompDirec = "";
      let arrDirecciones = [];
      let arrStrDirecciones = [];
      if (idComunidad) {
        const instancias = comuComus[0]["Instancias"] && comuComus[0]["Instancias"];

        arrDirecciones =
          Array.isArray(instancias) &&
          instancias.filter((instancia) => instancia.Usuario === childKey && instancia.Tipo !== "empleadocomunidad");
        Array.isArray(arrDirecciones) &&
          arrDirecciones.map((direcc) => {
            arrStrDirecciones.push(getDireccionCompleta(direcc));
          });
        arrStrDirecciones = arrStrDirecciones.filter((element) => element !== "");
        strCompDirec = arrStrDirecciones.length > 0 ? arrStrDirecciones.join(", ") : "";
      } else {
        // Si no nos llega idComunidad tendré que recorrer todas a las que pertenezca el usuario
        let instanciasMergeadas = [];
        comuComus.map((comu) => {
          const insts = comu.Instancias && comu.Instancias;

          arrDirecciones =
            Array.isArray(insts) &&
            insts.filter((instancia) => instancia.Usuario === childKey && instancia.Tipo !== "empleadocomunidad");
          instanciasMergeadas = instanciasMergeadas.concat(arrDirecciones);
        });
        arrDirecciones = instanciasMergeadas;
        Array.isArray(arrDirecciones) &&
          arrDirecciones.map((direcc) => {
            arrStrDirecciones.push(getDireccionCompleta(direcc));
          });

        arrStrDirecciones = arrStrDirecciones.filter((element) => element !== "");
        strCompDirec = arrStrDirecciones.length > 0 ? arrStrDirecciones.join(", ") : "";
      }

      if (childData.Administrador !== true && childData.Borrado !== true) {
        if (idComunidad && childData.Comunidades && Object.keys(childData.Comunidades).includes(idComunidad)) {
          datos.push({
            label: nombreProp + " | " + strCompDirec,
            value: childKey,
            nombre: childData.Nombre,
            Nombre: childData.Nombre,
            DNI: childData.DNI,
            apellido: childData.Apellido,
            Apellido: childData.Apellido,
            idInterno: childData.idInterno,
            direcciones: arrDirecciones,
            Direcciones: arrDirecciones,
            email: childData.Email,
            Email: childData.Email,
            Roles: childData.Comunidades[idComunidad] && childData.Comunidades[idComunidad].Roles,
          });
        } else if (!idComunidad) {
          datos.push({
            label: nombreProp + " | " + strCompDirec,
            value: childKey,
            nombre: childData.Nombre,
            Nombre: childData.Nombre,
            DNI: childData.DNI,
            apellido: childData.Apellido,
            Apellido: childData.Apellido,
            idInterno: childData.idInterno,
            direcciones: arrDirecciones,
            Direcciones: arrDirecciones,
            email: childData.Email,
            Email: childData.Email,
            Roles: childData.Comunidades[idComunidad] && childData.Comunidades[idComunidad].Roles,
          });
        }
      }
    });

    // Filtro los que no tienen viviendas
    datos = datos.filter((usuario) => usuario.direcciones.length > 0);
    // Filtro los que sólo tienen una vivienda o más de tipo inquilino
    datos = filterOnlyInquilinos(datos);
    datos = filterOnlyEmpresas(datos);

    return datos;
  } catch (error) {
    console.error("Error al cargar usuarios", error);
  }
};

const filterOnlyInquilinos = (elements) => {
  return elements.filter((element) => element.direcciones.some((direccion) => direccion.Tipo !== "inquilino"));
};
const filterOnlyEmpresas = (elements) => {
  return elements.filter((element) => element.direcciones.some((direccion) => direccion.Tipo !== "empresa"));
};

function traeInstancias(instancias1) {
  const instancias = instancias1;
  let Direcciones2 = [];
  let Direccion = [];
  let Enteros = 0;
  if (instancias && instancias.length > 0) {
    instancias.map((instancia) => {
      Direcciones2 =
        Direcciones2 +
        instancia.Calle +
        (instancia.Portal ? " " + instancia.Portal : "") +
        (instancia.Escalera ? " " + instancia.Escalera : "") +
        (instancia.Piso ? " " + instancia.Piso : "") +
        (instancia.Letra ? " " + instancia.Letra : "") +
        "\n";

      Direccion.push({
        calle:
          instancia.Calle +
          " " +
          instancia.Portal +
          " " +
          instancia.Escalera +
          " " +
          instancia.Piso +
          " " +
          instancia.Letra,
        /*  portal: instancia.Portal,
        escalera: instancia.Escalera,
        piso: instancia.Piso,
        letra: instancia.Letra, */
      });

      Enteros = Enteros + instancia.Enteros;
    });

    return {
      Instancias: instancias,
      Enteros: Enteros,
      Direccion: Direccion,
      Direcciones2: Direcciones2,
    };
  }
}
export const JosePrueba = async (firebase, IdComu, idJunta, votacion) => {
  let AsistentesTipoAsistencia = [];
  const asistentes = await get(firebase.getReferencia("Comunidad/" + IdComu + "/Juntas/" + idJunta + "/Asistentes"));
  asistentes.forEach((asistente) => {
    const vecino = asistente.val();
    const clave = asistente.key;
    AsistentesTipoAsistencia[vecino.DNI] = {
      TipoAsistencia: vecino.TipoAsistencia,
      Delegado: vecino.Delegado,
      Nombre: vecino.Nombre + " " + vecino.Apellido,
      DNI: vecino.DNI,
    };
  });

  const opcionesVotacion = { SI: "", NO: "", ABS: "" };

  const votos = await get(firebase.getReferencia("Comunidad/" + IdComu + "/Votaciones/" + votacion + "/Voto"));
  votos.forEach((voto) => {
    const votoVecino = voto.val();
    const claveVoto = voto.key;

    if (AsistentesTipoAsistencia[claveVoto]) {
      /// sumar totales
      opcionesVotacion[votoVecino.voto] = Number(opcionesVotacion[votoVecino.voto]) + 1;
      let enterosTotales = 0;
      votoVecino.Instancias.forEach((voto) => {
        if (voto.Enteros) {
          enterosTotales = enterosTotales + Number(voto.Enteros);
        }
      });

      AsistentesTipoAsistencia[claveVoto] = {
        ...AsistentesTipoAsistencia[claveVoto],
        [votoVecino.voto]: "X",
        Enteros: enterosTotales,
      };
    }
  });

  AsistentesTipoAsistencia = {
    ...AsistentesTipoAsistencia,
    opcionesVotacion,
  };
  const arrayData = Object.entries(AsistentesTipoAsistencia).map(([key, value]) => ({
    id: key, // clave como ID
    ...value,
  }));
  return arrayData;
};

export const traerVotacionesCombinadas = async (firebase, IdComu, idJunta, Identificador, diccionario) => {
  const snapshot2 = await get(firebase.getReferencia(`Comunidad/${Identificador}`));

  //const direccionComun = snapshot2.val().Direccion;
  const instancias = snapshot2.val().Instancias;

  let AsistentesTipoAsistencia = [];
  const asistentes = await get(firebase.getReferencia("Comunidad/" + IdComu + "/Juntas/" + idJunta + "/Asistentes"));
  asistentes.forEach((asistente) => {
    const vecino = asistente.val();
    const clave = asistente.key;
    let arrDirecciones = [];
    let enterosUsu = 0;
    arrDirecciones =
      Array.isArray(instancias) &&
      instancias
        .filter((instancia) => instancia.Usuario === vecino.DNI && instancia.Tipo !== "empleadocomunidad")
        .map((instancia) => {
          enterosUsu = enterosUsu + Number(instancia.Enteros);
          return getDireccionCompleta(instancia);
        });
    const DireccionSalto =
      arrDirecciones.length > 0 &&
      arrDirecciones
        .map((direcc) => direcc) // Mapea las direcciones
        .slice(0, -1) // Obtiene todos menos el último elemento
        .join("<w:br/>") + // Une los elementos anteriores con <w:br/>
        (arrDirecciones.length > 1 ? "<w:br/>" : "") + // Añade <w:br/> solo si hay más de un elemento
        arrDirecciones[arrDirecciones.length - 1]; // Agrega el último elemento sin <w:br/>

    AsistentesTipoAsistencia[vecino.DNI] = {
      Propiedades: DireccionSalto,
      Nombre: vecino.Nombre + " " + vecino.Apellido,
      TipoAsistencia: vecino.TipoAsistencia === "" ? "" : vecino.TipoAsistencia === "DELEGADA" ? vecino.Delegado : "X",
      Enteros: enterosUsu,
      DNI: vecino.DNI,
    };
  });

  let votacionesVotos = [];
  let totales = [{}];
  let calcularTotales = [{}];
  let opcionesVotacion = {};
  const votaciones = await get(firebase.getReferencia("Comunidad/" + IdComu + "/Votaciones/"));
  votaciones.forEach((voto) => {
    const votoVotacion = voto.val();
    const clave = voto.key;

    let nombreVotacion = votoVotacion.Nombre;
    if (votoVotacion.Junta === idJunta) {
      opcionesVotacion = {};

      if (votoVotacion.Tipo !== "OPCIONES") {
        opcionesVotacion = {
          SI: 0,
          NO: 0,
          ABS: 0,
        };
      } else {
        votoVotacion.Criterios.forEach((value) => {
          opcionesVotacion[value] = 0;
        });
      }
      votacionesVotos[nombreVotacion] = {
        Votos: votoVotacion.Voto ? votoVotacion.Voto : [],
      };

      if (votoVotacion.Voto) {
        Object.entries(votoVotacion.Voto).forEach(([key, value]) => {
          opcionesVotacion[value.voto] = Number(opcionesVotacion[value.voto]) + 1;
        });
      }
      let totalVotos = 0;
      Object.entries(opcionesVotacion).forEach(([key, value]) => {
        totalVotos = totalVotos + value;
      });

      totales.push({
        [nombreVotacion]: opcionesVotacion,
      });
      calcularTotales.push({
        [nombreVotacion]: diccionario.totalVotosActa + totalVotos,
      });
    }
  });

  let votaNombre = "";
  Object.entries(AsistentesTipoAsistencia).forEach(([key, asistente]) => {
    Object.entries(votacionesVotos).forEach(([nombreVotacion, votacion]) => {
      votaNombre = nombreVotacion;

      if (votacion["Votos"][asistente["DNI"]]) {
        AsistentesTipoAsistencia[asistente["DNI"]] = {
          ...AsistentesTipoAsistencia[asistente["DNI"]],
          [nombreVotacion]: votacion["Votos"][asistente["DNI"]].voto,
        };
      }
    });
  });

  const totalesTuneados = Object.values(totales).reduce((acc, item) => {
    const [key, value] = Object.entries(item)[0] || [];
    if (key) {
      // Transformar todos los valores en un string
      const valueString = Object.entries(value)
        .map(([k, v]) => `<w:br/>${k}<w:tab/>:${v}<w:br/>`)
        .join(", ");
      acc[key] = valueString;
    }
    return acc;
  }, {});

  const calcularTotalesTuneados = Object.values(calcularTotales).reduce((acc, item) => {
    const [key, value] = Object.entries(item)[0] || [];
    if (key) {
      acc[key] = value;
    }
    return acc;
  }, {});

  // inserto modificado en el objeto, eran arrays y no objetos
  AsistentesTipoAsistencia = {
    ...AsistentesTipoAsistencia,
    totalesTuneados,
    calcularTotalesTuneados,
  };

  for (let key in AsistentesTipoAsistencia) {
    if (AsistentesTipoAsistencia[key].hasOwnProperty("DNI")) {
      delete AsistentesTipoAsistencia[key].DNI; // Elimino DNI para que no se muestre en el word de lacta
    }
  }

  const arrayData = Object.entries(AsistentesTipoAsistencia).map(([key, value]) => ({
    ...value,
  }));

  return arrayData;
};

export const traerVotacionesIndividuales = async (firebase, IdComu, idJunta, Identificador, diccionario, votacion) => {
  const snapshot2 = await get(firebase.getReferencia(`Comunidad/${Identificador}`));

  //const direccionComun = snapshot2.val().Direccion;
  const instancias = snapshot2.val().Instancias;

  let AsistentesTipoAsistencia = [];
  const asistentes = await get(firebase.getReferencia("Comunidad/" + IdComu + "/Juntas/" + idJunta + "/Asistentes"));
  asistentes.forEach((asistente) => {
    const vecino = asistente.val();
    const clave = asistente.key;
    let arrDirecciones = [];
    let enterosUsu = 0;
    arrDirecciones =
      Array.isArray(instancias) &&
      instancias
        .filter((instancia) => instancia.Usuario === vecino.DNI && instancia.Tipo !== "empleadocomunidad")
        .map((instancia) => {
          enterosUsu = enterosUsu + Number(instancia.Enteros);
          return getDireccionCompleta(instancia);
        });
    const DireccionSalto =
      arrDirecciones.length > 0 &&
      arrDirecciones
        .map((direcc) => direcc) // Mapea las direcciones
        .slice(0, -1) // Obtiene todos menos el último elemento
        .join("<w:br/>") + // Une los elementos anteriores con <w:br/>
        (arrDirecciones.length > 1 ? "<w:br/>" : "") + // Añade <w:br/> solo si hay más de un elemento
        arrDirecciones[arrDirecciones.length - 1]; // Agrega el último elemento sin <w:br/>
    AsistentesTipoAsistencia[vecino.DNI] = {
      Propiedades: DireccionSalto,
      Nombre: vecino.Nombre + " " + vecino.Apellido,
      TipoAsistencia: vecino.TipoAsistencia === "" ? "" : vecino.TipoAsistencia === "DELEGADA" ? vecino.Delegado : "X",
      Enteros: enterosUsu,
      DNI: vecino.DNI,
    };
  });

  let votacionesVotos = [];
  let totales = [{}];
  let calcularTotales = [{}];
  let opcionesVotacion = {};
  const votaciones = await get(firebase.getReferencia("Comunidad/" + IdComu + "/Votaciones/" + votacion + "/"));

  const votoVotacion = votaciones.val();

  const clave = votaciones.key;

  let nombreVotacion = votoVotacion.Nombre;
  if (votoVotacion.Junta === idJunta) {
    opcionesVotacion = {};

    if (votoVotacion.Tipo !== "OPCIONES") {
      opcionesVotacion = {
        SI: 0,
        NO: 0,
        ABS: 0,
      };
    } else {
      votoVotacion.Criterios.forEach((value) => {
        opcionesVotacion[value] = 0;
      });
    }
    votacionesVotos[nombreVotacion] = {
      Votos: votoVotacion.Voto ? votoVotacion.Voto : [],
    };

    if (votoVotacion.Voto) {
      Object.entries(votoVotacion.Voto).forEach(([key, value]) => {
        opcionesVotacion[value.voto] = Number(opcionesVotacion[value.voto]) + 1;
      });
    }
    let totalVotos = 0;
    Object.entries(opcionesVotacion).forEach(([key, value]) => {
      totalVotos = totalVotos + value;
    });

    totales.push({
      [nombreVotacion]: opcionesVotacion,
    });
    calcularTotales.push({
      [nombreVotacion]: diccionario.totalVotosActa + totalVotos,
    });
  }

  let votaNombre = "";
  Object.entries(AsistentesTipoAsistencia).forEach(([key, asistente]) => {
    Object.entries(votacionesVotos).forEach(([nombreVotacion, votacion]) => {
      votaNombre = nombreVotacion;

      AsistentesTipoAsistencia[asistente["DNI"]] = {
        ...AsistentesTipoAsistencia[asistente["DNI"]],
        [nombreVotacion]: votacion["Votos"][asistente["DNI"]] ? votacion["Votos"][asistente["DNI"]].voto : "",
      };
    });
  });

  let totalesTuneados = Object.values(totales).reduce((acc, item) => {
    const [key, value] = Object.entries(item)[0] || [];
    if (key) {
      // Transformar todos los valores en un string
      const valueString = Object.entries(value)
        .map(([k, v]) => `<w:br/>${k}<w:tab/>:${v}<w:br/>`)
        .join(", ");
      acc[key] = valueString;
    }
    return acc;
  }, {});

  let calcularTotalesTuneados = Object.values(calcularTotales).reduce((acc, item) => {
    const [key, value] = Object.entries(item)[0] || [];
    if (key) {
      acc[key] = value;
    }
    return acc;
  }, {});

  calcularTotalesTuneados = {
    Propiedades: "",
    Nombre: "",
    TipoAsistencia: "",
    Enteros: "",
    ...calcularTotalesTuneados,
  };

  totalesTuneados = {
    Propiedades: "",
    Nombre: "",
    TipoAsistencia: "",
    Enteros: "",
    ...totalesTuneados,
  };
  // inserto modificado en el objeto, eran arrays y no objetos
  AsistentesTipoAsistencia = {
    ...AsistentesTipoAsistencia,
    totalesTuneados,
    calcularTotalesTuneados,
  };

  for (let key in AsistentesTipoAsistencia) {
    if (AsistentesTipoAsistencia[key].hasOwnProperty("DNI")) {
      delete AsistentesTipoAsistencia[key].DNI; // Elimino DNI para que no se muestre en el word de lacta
    }
  }
  const arrayData = Object.entries(AsistentesTipoAsistencia).map(([key, value]) => ({
    ...value,
  }));

  return arrayData;
};
export const traerAsistentesComunidad = async (firebase, idComunidad, idJunta) => {
  try {
    const snapshot = await get(firebase.getReferencia(`Comunidad/${idComunidad}/Juntas/${idJunta}/Asistentes`, null));
    let datos = [];
    snapshot.forEach((childSnapshot) => {
      const childKey = childSnapshot.key;
      const childData = childSnapshot.val();
      childData.idInterno = childKey;
      datos.push(childData);
    });
    return datos;
  } catch (error) {
    console.error("Error al cargar usuarios", error);
  }
};

export const traerAdministradoresComunidad = async (firebase, idComunidad = null) => {
  let adminUsers = null;
  try {
    adminUsers = await loadAdminUsers(firebase);
    // Filtro los que tengan perfil de admon
    adminUsers = adminUsers.filter((usr) => usr.PerfilEmpleadoOficina === "Administrador");
    return adminUsers;
  } catch (error) {
    console.error("Error al cargar usuarios", error);
  }
};

export const traerImportantesComunidad = async (firebase, idComunidad) => {
  try {
    const propietarios = await traerPropietariosComunidad(firebase, idComunidad);

    const presidentes = propietarios.filter(
      (usr) => usr.Roles && Array.isArray(usr.Roles) && usr.Roles.includes("Presidente")
    );
    const vicepresidentes = propietarios.filter(
      (usr) => usr.Roles && Array.isArray(usr.Roles) && usr.Roles.includes("Vicepresidente")
    );
    const vocales = propietarios.filter((usr) => usr.Roles && Array.isArray(usr.Roles) && usr.Roles.includes("Vocal"));

    return {
      presidentes: presidentes,
      vicepresidentes: vicepresidentes,
      vocales: vocales,
    };
  } catch (error) {
    console.error("Error al cargar usuarios", error);
  }
};

export const getListaTipos = (diccionario) => {
  return [
    { label: diccionario.ORDINARIA, value: "ORDINARIA" },
    { label: diccionario.EXTRAORDINARIA, value: "EXTRAORDINARIA" },
  ];
};
export const getListaConvocantes = (diccionario) => {
  return [
    { label: diccionario.PRESIDENTE, value: "PRESIDENTE" },
    { label: diccionario.FIRMANTES, value: "FIRMANTES" },
  ];
};
