import { get } from "firebase/database";
import { Semaforito } from "app/components/Semaforito/Semaforito";
import { PDFDocument, rgb } from "pdf-lib";
import { CurrencyFormatter } from "app/components/helpers/Views";

export const getNombreComunidad = async (firebase, idComunidad) => {
  const snapshot = await get(firebase.getReferencia(`Comunidad/${idComunidad}/Nombre`, null));
  const nombre = snapshot.val();
  return nombre;
};
export const descargarPDFContrato = async (contrato, nombreFichero) => {
  // 1. Cargar el archivo PDF como array buffer
  console.log("contrato", contrato);

  const url = "/assets/files/cotrato.pdf"; // Ruta al PDF
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  // 2. Cargar el PDF con pdf-lib
  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  // Obtener el formulario
  const form = pdfDoc.getForm();

  // Obtener todos los campos de formulario
  const fields = form.getFields();

  fields.forEach((field) => {
    console.log("Campo:", field.getName());
  });

  const nameFieldPlan = form.getTextField("plan"); // Nombre del campo en el formulario
  nameFieldPlan.setText(contrato.plan.charAt(0).toUpperCase() + contrato.plan.slice(1));
  const nameFieldPrecio = form.getTextField("precio");
  nameFieldPrecio.setText(CurrencyFormatter(contrato.precioSelected.toString()));
  const nameFieldFormaPago = form.getTextField("formapago");
  nameFieldFormaPago.setText(
    contrato.formapago.charAt(0).toUpperCase() + contrato.formapago.slice(1)
  );
  const nameFieldVecinos = form.getTextField("vecinos");
  nameFieldVecinos.setText(contrato.vecinos.toString());

  form.flatten();
  const pages = pdfDoc.getPages();
  const firstPage = pages[3];

  // 4. Guardar el PDF modificado
  const pdfBytes = await pdfDoc.save();

  // Descargar el PDF
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = nombreFichero + ".pdf";
  link.click();
};

export const getComunidad = async (firebase, idComunidad) => {
  const snapshot = await get(firebase.getReferencia(`Comunidad/${idComunidad}`, null));
  const comunidad = snapshot.val();
  return comunidad;
};

export const getTodasComunidades = async (firebase) => {
  const comunidades = [];
  const snapshot = await get(firebase.getReferencia("Comunidad", null));
  snapshot.forEach((childSnapshot) => {
    let cm = childSnapshot.val();
    cm.idInterno = childSnapshot.key;
    if (!cm.Borrado) {
      let numViviendas = 0;
      if (cm.Instancias) {
        numViviendas = cm.Instancias.filter((instancia) => instancia.Usuario != "").length;
      }
      cm.Vecinos = numViviendas;

      comunidades.push(cm);
    }
  });
  return comunidades;
};

export const getComunidades = async (firebase) => {
  const comunidades = [];
  const snapshot = await get(firebase.getReferencia("Comunidad", null));
  snapshot.forEach((childSnapshot) => {
    comunidades[childSnapshot.key] = childSnapshot.val().Nombre;
  });
  return comunidades;
};

export const getSemaforoComunidad = (rowData, diccionario) => {
  let colorSeleccionado = "green";
  let textoExplicativo = diccionario.comunidadActiva;

  if (rowData.Alta === false) {
    colorSeleccionado = "yellow";
    textoExplicativo = diccionario.comunidadInactiva;
  }
  if (rowData.bloqueada === true) {
    colorSeleccionado = "red";
    textoExplicativo = diccionario.comunidadBloqueada;
  }

  rowData.colorSeleccionado = colorSeleccionado;
  rowData.textoExplicativo = textoExplicativo;
  return (
    <Semaforito
      rowdata={rowData}
      colorSeleccionado={colorSeleccionado}
      textoExplicativo={textoExplicativo}
    />
  );
};
