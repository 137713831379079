import hash from "object-hash";
import moment from "moment";
import { getFunctions, httpsCallable } from "firebase/functions";

const obtenerMetas = async (Identificador, firebase, functions) => {
  const obtenerMetadatosUsuario = httpsCallable(functions, "getOwnerMetadata");

  await obtenerMetadatosUsuario({
    id: Identificador,
    mode: "user",
  }).then((data) => {
    const usuarioData = data.data[Identificador];
    if (usuarioData) {
      return usuarioData;
    }
  });
};

const convertDecimalHoursToHMS = (decimalHours) => {
  const hours = Math.floor(decimalHours);
  const minutesDecimal = (decimalHours - hours) * 60;
  const minutes = Math.floor(minutesDecimal);
  const seconds = Math.round((minutesDecimal - minutes) * 60);

  return {
    hours,
    minutes,
    seconds,
  };
};

const normalizeString = (str) => {
  return str
    .split("") // Convertir la cadena en un array de caracteres
    .map((char) => {
      if (char === "ñ" || char === "Ñ") return char; // Si entra con ñ o Ñ, devolverlo tal cual
      return char.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // eliminar acentos y diéresis
    })
    .join(""); // Convertir el array de caracteres en una cadena de texto
};

const SearchArray = (myArray, searchTerm) => {
  console.log("Entro con", myArray, "y esto", searchTerm);
  const arrSeach = Array.isArray(myArray) ? myArray : [];
  for (const item of arrSeach) {
    if (
      normalizeString(item.toLowerCase()).indexOf(normalizeString(searchTerm.toLowerCase())) !== -1
    ) {
      return true;
    }
  }

  return false;
};

function IsDateOlderThanNDays(dateString, daysToCompare = 8) {
  // Extract date components from the dateString
  const [datePart, timePart] = dateString.split(" ");
  const [day, month, year] = datePart.split("-");
  const [hours, minutes] = timePart.split(":");

  // Convert dateString components to numbers
  const yearNumber = parseInt(year, 10);
  const monthNumber = parseInt(month, 10) - 1; // Month is zero-based in JavaScript Date object
  const dayNumber = parseInt(day, 10);
  const hoursNumber = parseInt(hours, 10);
  const minutesNumber = parseInt(minutes, 10);

  // Create a Date object from dateString components
  const date = new Date(yearNumber, monthNumber, dayNumber, hoursNumber, minutesNumber);

  // Calculate the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMs = currentDate - date;

  // Convert milliseconds to days
  const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

  // Check if the difference is greater than the specified number of days
  return differenceInDays > daysToCompare;
}

const IsDateOlderThanNDays_OLD = (dateString, daysToCompare = 8) => {
  // Parse the date string into a Date object
  const dateParts = dateString.split(/[\s-:]/);
  const parsedDate = new Date(
    dateParts[2],
    dateParts[1] - 1,
    dateParts[0],
    dateParts[3],
    dateParts[4]
  );

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = currentDate - parsedDate;
  // Convert the difference to days
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
  // Check if the date is older than 8 days
  return differenceInDays >= daysToCompare;
};

const extractPaths = (objects) => {
  let paths = [];

  for (const obj of objects) {
    paths.push(obj.path);
    if (obj.folders && obj.folders.length > 0) {
      paths = paths.concat(extractPaths(obj.folders));
    }
  }

  return paths;
};

const transformPaths = (paths, origin, destiny) => {
  return paths.map((path) => {
    if (path.startsWith(origin)) {
      const suffix = path.substring(origin.length);
      return `${destiny}${suffix}`;
    } else {
      return path;
    }
  });
};

const flattenRoutes2 = (obj, currentPath = "", result = {}) => {
  for (const key in obj) {
    const newPath = currentPath ? `${currentPath}/${key}` : key;

    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      flattenRoutes(obj[key], newPath, result);
    } else {
      if (!isNaN(key) || (Array.isArray(obj) && parseInt(key) < obj.length)) {
        const daPath = newPath.replace(/\/[^/]+$/, "");
        if (!Array.isArray(result[daPath])) {
          result[daPath] = [];
        }
        result[daPath].push(obj[key]);
      } else {
        result[newPath] = obj[key];
      }
    }
  }
  return result;
};

const flattenRoutes = (obj, prefix = "") => {
  let result = {};

  for (const key in obj) {
    const currentKey = prefix ? `${prefix}/${key}` : key;

    if (Array.isArray(obj[key])) {
      result[currentKey] = obj[key].map((role) => [role]);
    } else if (typeof obj[key] === "object") {
      result = { ...result, ...flattenRoutes(obj[key], currentKey) };
    } else {
      result[currentKey] = [obj[key]];
    }
  }

  return result;
};

const findNodeById = (nodes, targetId) => {
  for (const node of nodes) {
    if (node.id === targetId) {
      return node; // Found the node with the matching id
    }

    if (node.folders && node.folders.length > 0) {
      const foundInFolders = findNodeById(node.folders, targetId);
      if (foundInFolders) {
        return foundInFolders; // Found in the subfolders
      }
    }
  }

  return null; // Node with the specified id not found
};
const renameNodeById = (nodes, targetId, destinyId) => {
  for (const node of nodes) {
    if (node.id === targetId) {
      node.id = destinyId;
      node.path = destinyId;
      node.name = "pepe";
      return node; // Return the modified node
    }

    if (node.folders && node.folders.length > 0) {
      const foundInFolders = renameNodeById(node.folders, targetId, destinyId);
      if (foundInFolders) {
        return foundInFolders; // Return the modified node found in the subfolders
      }
    }
  }

  return null; // Node with the specified id not found
};

const getCambiosDetectadosIIII = (a, b) => {
  const aKeys = Object.keys(a).sort();
  const bKeys = Object.keys(b).sort();
  const changes = [];

  if (aKeys.length !== bKeys.length || aKeys.join("") !== bKeys.join("")) {
    return changes; // Return empty array if objects have different keys
  }

  for (let i = 0; i < aKeys.length; i++) {
    const key = aKeys[i];
    if (a[key] !== b[key]) {
      changes.push({
        fieldChanged: key,
        oldValue: a[key],
        newValue: b[key],
      });
    }
  }
  return changes;
};

const getCambiosDetectados = (oldObj, newObj, path = []) => {
  const changes = [];

  for (const key in oldObj) {
    if (!(key in newObj)) {
      changes.push({
        propertyName: [...path, key].join("."),
        oldValue: oldObj[key],
        newValue: undefined,
      });
    } else if (typeof oldObj[key] === "object" && typeof newObj[key] === "object") {
      changes.push(...getCambiosDetectados(oldObj[key], newObj[key], [...path, key]));
    } else if (oldObj[key] !== newObj[key]) {
      changes.push({
        propertyName: [...path, key].join("."),
        oldValue: oldObj[key],
        newValue: newObj[key],
      });
    }
  }

  for (const key in newObj) {
    if (!(key in oldObj)) {
      changes.push({
        propertyName: [...path, key].join("."),
        oldValue: undefined,
        newValue: newObj[key],
      });
    }
  }

  return changes;
};

const areObjectsEqual = (obj1, obj2) => {
  // Verificar si ambos son objetos
  if (typeof obj1 !== "object" || typeof obj2 !== "object") {
    return obj1 === obj2; // Si no son objetos, comparar directamente
  }

  // Verificar si ambos son nulos
  if (obj1 === null || obj2 === null) {
    return obj1 === obj2; // Si uno de ellos es null, verificar si ambos son null
  }

  // Obtener las claves de ambos objetos
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Verificar si tienen la misma cantidad de claves
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Verificar si las claves son las mismas
  if (!keys1.every((key) => keys2.includes(key))) {
    return false;
  }

  // Verificar si los valores de las claves son iguales
  return keys1.every((key) => areObjectsEqual(obj1[key], obj2[key]));
};
const CompareObj = (a, b) => {
  var aKeys = Object.keys(a).sort();
  var bKeys = Object.keys(b).sort();
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  if (aKeys.join("") !== bKeys.join("")) {
    return false;
  }
  for (var i = 0; i < aKeys.length; i++) {
    if (a[aKeys[i]] !== b[bKeys[i]]) {
      return false;
    }
  }
  return true;
};

const compareObjectsByHashes = (obj1, obj2) => {
  const hash1 = hash(obj1);
  const hash2 = hash(obj2);

  return hash1 !== hash2;
};

const sanitizeFilename = (input) => {
  // Define a list of characters that are not allowed in filenames
  const illegalRe = /[\/\?<>\\:\*\|":]/g;
  const controlRe = /[\x00-\x1f\x80-\x9f]/g;
  const reservedRe = /^\.+$/;
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])$/i;
  const windowsTrailingRe = /[\. ]+$/;

  // Replace illegal characters with an underscore
  let sanitized = input
    .replace(illegalRe, "_")
    .replace(controlRe, "_")
    .replace(reservedRe, "_")
    .replace(windowsReservedRe, "_")
    .replace(windowsTrailingRe, "_");

  // Trim to a reasonable length if necessary (255 characters is a common limit)
  const maxLength = 255;
  if (sanitized.length > maxLength) {
    sanitized = sanitized.substring(0, maxLength);
  }

  return sanitized;
};

const getDireccionCompleta = (rowData) => {
  try {
    const parts = [
      rowData["Calle"],
      rowData["Numero"],
      rowData["Portal"],
      rowData["Escalera"],
      rowData["Piso"],
      rowData["Letra"],
    ];

    // Filter out empty or undefined parts
    const nonEmptyParts = parts.filter((part) => part && part.trim() !== "");

    // Join non-empty parts with a space
    const dir = nonEmptyParts.join(" ");

    return dir;
  } catch (error) {
    console.log(error, rowData);
  }
};

const validateEuropeanPhoneNumber = (phoneNumber) => {
  // Regex pattern for local European phone numbers and international format with spaces and hyphens
  const europeanPhoneRegex = /^(?:\+([3-4]\d{1,2})\s?)?([\d\s-]{6,20})$/;

  // Check if the input is a comma-separated list
  if (phoneNumber.includes(",")) {
    // Split the string by commas, trim whitespace, and validate each phone number
    const phoneNumbers = phoneNumber.split(",").map((p) => p.trim());
    return phoneNumbers.every((p) => europeanPhoneRegex.test(p)); // Returns true only if all phone numbers are valid
  }

  // Validate a single phone number
  return europeanPhoneRegex.test(phoneNumber);
};

const timestampToFecha = (timestmp) => {
  const fecha = timestmp && timestmp != "" ? moment(timestmp).format("DD/MM/YYYY HH:mm:ss") : "";
  return fecha;
};

function truncarCadena(cadena, longitudMaxima) {
  if (cadena.length <= longitudMaxima) {
    return cadena;
  } else {
    return cadena.slice(0, longitudMaxima) + "...";
  }
}

export {
  getCambiosDetectados,
  CompareObj,
  areObjectsEqual,
  SearchArray,
  findNodeById,
  flattenRoutes,
  convertDecimalHoursToHMS,
  renameNodeById,
  extractPaths,
  transformPaths,
  IsDateOlderThanNDays,
  normalizeString,
  compareObjectsByHashes,
  sanitizeFilename,
  getDireccionCompleta,
  timestampToFecha,
  truncarCadena,
  obtenerMetas,
  validateEuropeanPhoneNumber,
};
