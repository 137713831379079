import { getFunctions, httpsCallable } from "firebase/functions";

export async function Messenger1(basededatosactual, firebase, objUser) {

  var UJET = window.UJET;
  try {

    if(!UJET){
      await window.initializeUJET();
      UJET = window.UJET;
    }
    
    var ujet = new UJET({
      logo: "https://conecta-c365.firebaseapp.com/assets/images/Imagenes/logo_app_transparente.png",
      // widget position
      right: "50px",
      bottom: "150px",

      companyId: "17139048722842872cb3d56ef53938e3a",

      // authenticate: getAuthToken(),
      //authenticate: getAuthToken,
      // companyId: '{​{ COMPANY KEY }​}'
      lang: "es",

      host: "https://jorge-wpdyft6.ew1.ccaiplatform.com",
      customData: {
        basedatosactual: {
          label: "basedatosactual",
          value: basededatosactual,
        },
        urlcabecera: {
          label: "urlcabecera",
          value: window.location.origin,
        },
      },
    });



  } catch (error) {
    console.log("Error cargando UJET", error,UJET);
  }

  const eltoken = await getAuthToken();
  // ujet.authenticate(getAuthToken);
  //  ujet.start()

  ujet.on("created", function () {
    ujet.start({ menuKey: "Soporte" });
    ujet.authenticate(eltoken);
  });
  ujet.on("ready", function () {
    ujet.start({ menuKey: "Soporte" });
    const pepe = "ready to talk";
  });

  ujet.on("close", function () {
    const pepe = "ready to talk";
  });

  ujet.registerHook("loading", function (e) {
    const baseactual = basededatosactual;
    const url1 = window.location.origin;
    ujet.start({ menuKey: "Soporte" });

    const pepe = "loading" + e;
  });

  ujet.registerHook("open", function (e) {
    const pepe = "Click to close" + e;
    //   ujet.open();
  });

  ujet.registerHook("close", function (e) {
    const pepe = "Click to open" + e;
    //  ujet.close();
  });
  ujet.registerHook("created", function (e) {
    const pepe = "Click to open" + e;
    //  ujet.close();
  });
  return ujet;
  function getAuthToken() {
    const functions = getFunctions(firebase.app, "europe-west1");
    const getCCAIToken = httpsCallable(functions, "getCCAIToken");
    const url = window.location.origin;

    return getCCAIToken({
      payload: {
        identifier: objUser.Clave,
        name: objUser.Nombre + " " + objUser.Apellido,
        email: objUser.Email,
        //  phone:customClaims,
        basedatosactual: basededatosactual,
        urlcabecera: url,
      },
    })
      .then((res) => {
        console.log("OK" + JSON.stringify(res.data));
        const ere = res.data;

        return {
          token: res.data,
          user: {
            identifier: objUser.Clave,
            name: objUser.Nombre + " " + objUser.Apellido,
            email: objUser.Email,
            //   phone: '000000000' //optional
          },
        };
        //('Envío correcto',res);
      })
      .catch((e) => {
        //alert(e);
        console.log("Envío fallido", e);
      });
  }
}

let translation = `{
  "en": {
  "ujet_start_title": "Hi!"
  },
  "es": {
  "ujet_start_title": "¡Hola!"
  },
  "fr": {
  "ujet_start_title": "Salut!"
  },
  "de": {
  "ujet_start_title": "Hallo!"
  },
  "it": {
  "ujet_start_title": "Ciao!"
  },
  "ja": {
  "ujet_start_title": "こんにちは！"
  },
  "ko": {
  "ujet_start_title": "안녕하세요!"
  },
  "pt": {
  "ujet_start_title": "Olá!"
  },
  "pt-BR": {
  "ujet_start_title": "Olá!"
  },
  "sv": {
  "ujet_start_title": "Hej!"
  }
  
  }`;

const estilo = () => {
  const estilo1 = document.createElement("style");
  estilo1.setAttribute("id", "estilo1");
  estilo1.innerHTML =
    "df-messenger {z-index: 999;position: fixed;bottom: 16px;right: 16px;--df-messenger-bot-message: #bdfcbf;      --df-messenger-button-titlebar-color: #f9fbf8;        --df-messenger-font-color: black; --df-messenger-send-icon: #7ec472;      --df-messenger-user-message: #7ec472;      --df-messenger-chat-window-width: 500px;      --df-messenger-chat-window-height: 700px;      --df-messenger-chat-background: #E8F3FC;      --df-messenger-titlebar-background:#4BA3EB;      --df-messenger-titlebar-icon-width: 100px;      --df-messenger-titlebar-icon-height: 100px;      --df-messenger-titlebar-icon-padding: -10px 2px -10px 0;      --df-messenger-titlebar-title-font-weight: 'bold';      --df-messenger-titlebar-title-font-size: 25px;      --df-messenger-titlebar-font-color: #ffffff;      --df-messenger-video-width: 250px;      --df-messenger-video-width: 250px;     --df-messenger-input-background: #8FC5F3;   ";
  return estilo1;
};

const dfmessenger = () => {
  const dfmessenger1 = document.createElement("df-messenger");

  dfmessenger1.setAttribute("id", "df-messenger");
  dfmessenger1.setAttribute("location", "europe-west1");

  dfmessenger1.setAttribute("project-id", "conecta-c365");
  dfmessenger1.setAttribute("agent-id", "6f0ebfbe-2072-48c0-af9f-fbe2ef993aca");
  dfmessenger1.setAttribute("language-code", "es");
  //  dfmessenger1="id='df-messenger' location='europe-west1' project-id='conecta-c365' agent-id='6f0ebfbe-2072-48c0-af9f-fbe2ef993aca' language-code='es'"

  const dfmessengerchatbubble = document.createElement(
    "df-messenger-chat-bubble"
  );

  dfmessengerchatbubble.setAttribute("chat-title", "Jorge");
  dfmessengerchatbubble.setAttribute(
    "chat-title-icon",
    "https://firebasestorage.googleapis.com/v0/b/conecta-c365.appspot.com/o/Imagenes%2Fchatbot-cutout.png?alt=media&token=7e139139-cbbe-4089-821d-0fb0425e5596"
  );
  //dfmessengerchatbubble.setAttribute("bot-actor-image", "https://firebasestorage.googleapis.com/v0/b/dogarfirbase.appspot.com/o/Imagenes%2FPrincipal2%2Fvecteezy_cute-robot-vector-illustration-with-happy-expression_5381497.jpg?alt=media&token=3ec45ff7-5307-4f00-9a22-27d9b8f1111f");

  dfmessengerchatbubble.setAttribute(
    "bot-actor-image",
    "https://firebasestorage.googleapis.com/v0/b/conecta-c365.appspot.com/o/Imagenes%2FCaptura%20de%20pantalla%202024-02-21%20a%20las%209.09.51.png?alt=media&token=0ab6c52f-8dc4-4f4d-ab85-50cdcd9fb045"
  );

  // dfmessengerchatbubble.innerHTML="chat-title='Jorge' chat-title-icon='https://firebasestorage.googleapis.com/v0/b/conecta-c365.appspot.com/o/Imagenes%2Fchatbot-cutout.png?alt=media&token=7e139139-cbbe-4089-821d-0fb0425e5596' bot-actor-image='https://firebasestorage.googleapis.com/v0/b/dogarfirbase.appspot.com/o/Imagenes%2FPrincipal2%2Fvecteezy_cute-robot-vector-illustration-with-happy-expression_5381497.jpg?alt=media&token=3ec45ff7-5307-4f00-9a22-27d9b8f1111f'"

  dfmessenger1.appendChild(dfmessengerchatbubble);
  return dfmessenger1;
};

export const dfeventos = (basededatosactual) => {
  const dfMessenger = document.querySelector("df-messenger");

  // Creating a custom event with additional data
  const customEventData = { despacho: basededatosactual };
  const cambioDespachoEvent = new CustomEvent("cambio-despacho", {
    detail: customEventData,
  });
  // Dispatching the custom event
  dfMessenger.dispatchEvent(cambioDespachoEvent);

  dfMessenger.addEventListener("df-messenger-loaded", () => {
    // An example of handling events: Navigate to the first suggested URL.
    dfMessenger.addEventListener("df-chat-open-changed", (event) => {
      console.log(`Chat is ${event.detail.isOpen ? "open" : "closed"}`);

      if (event.detail.isOpen) {
        const dfMessenger = document.querySelector("df-messenger");
        dfMessenger.startNewSession({ retainHistory: false });

        const url = window.location.origin;
        const queryParameters = {
          parameters: {
            basedatosactual: basededatosactual,
            urlcabecera: url,
          },
        };
        dfMessenger.setQueryParameters(queryParameters);
        dfMessenger.renderCustomText(
          "HOLA!!! ,Soy Jorge, tu asistente virtual, estaré encantado de poderte ayudar sobre cualquier duda en cuanto a la plataforma C365. \nDime, ¿En qué necesitas soporte?",
          true
        );
        //     dfMessenger.renderCustomText(basededatosactual, true);
      }
    });
    dfMessenger.addEventListener("df-messenger-error", (event) => {
      console.log(event.detail.error);
    });

    dfMessenger.addEventListener("cambio-despacho", (event) => {
      const customEventData = event.detail;
      const url = window.location.origin;

      const despachoValue = customEventData.despacho;
      // Use the despachoValue as needed
      dfMessenger.setQueryParameters({
        parameters: {
          basedatosactual: despachoValue,
          urlcabecera: url,
        },
      });
      //   dfMessenger.renderCustomText(despachoValue, true);
    });
  });
};
